import React from "react";

import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <HomeIcon />,
    cName: "nav-text",
  },
  {
    title: "Statistiche",
    path: "/statistiche",
    icon: <QueryStatsIcon />,
    cName: "nav-text",
  },
  {
    title: "Carica Gratta&Vinci",
    path: "/carica-gratta-e-vinci",
    icon: <AddShoppingCartIcon />,
    cName: "nav-text",
  },
  {
    title: "Magazzino",
    path: "/magazzino",
    icon: <SettingsSuggestIcon />,
    cName: "nav-text",
  },
  {
    title: "Help",
    path: "/help",
    icon: <LiveHelpIcon />,
    cName: "nav-text",
  },
  {
    title: "Contattaci",
    path: "/contactform",
    icon: <AttachEmailIcon />,
    cName: "nav-text",
  },
];
