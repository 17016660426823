import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import "../styles/Magazzino.css";
import { errorReset } from "../store/actions/handleHome";
import { fetchData } from "../store/actions/handleMagazzino";
import GrattaCard from "../components/GrattaCard";
import HeaderCardGroup from "../components/HeaderCardGroup";
import { stateChange } from "../functions/stateChange";
import Spinner from "../components/Spinner";
import Navbar from "../components/Navbar";

function Magazzino() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const listGrattaArr = useSelector((state) => state.magazzinoReducers.listGrattaArr);
  const loading = useSelector((state) => state.magazzinoReducers.loading);
  const listgratta = useSelector((state) => state.magazzinoReducers.listgratta);

  useEffect(() => {
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchData());
    }
  }, [dispatch, token]);

  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  const renderMagazzino = () => {
    return listgratta.map((x) => (
      <div className="single-header">
        <HeaderCardGroup title={x + "€"} />
        <div className="card-group">
          {listGrattaArr[x].map((y) => (
            <GrattaCard nome={y.nome} codiceGratta={y.codiceGratta} imgM={y.imgM} />
          ))}
        </div>
      </div>
    ));
  };
  return (
    <>
      {token ? <Navbar isAutenticated={token} /> : null}
      {loading || listGrattaArr[25] === undefined ? (
        <Spinner />
      ) : (
        <div className="mag-mag">{renderMagazzino()}</div>
      )}
    </>
  );
}

export default Magazzino;
