import React from "react";
import "../styles/HeaderCardGroup.css";

function HeaderCardGroup({ title }) {
  return (
    <div className="h-card-group">
      <h1>{title}</h1>
    </div>
  );
}

export default HeaderCardGroup;
