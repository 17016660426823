import { useState } from "react";
import "../styles/Auth.css";
import "../styles/Registrazione.css";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../store/actions/handelAuth";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";

const Auth = () => {
  const [email, setmail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const userToken = useSelector((state) => state.authReducer.token);
  const error = useSelector((state) => state.authReducer.error);

  const dispatch = useDispatch();
  let type = localStorage.getItem("type");
  console.log(type);
  // useEffect(() => {
  //   type = localStorage.getItem("type");
  // }, []);

  const handleEmail = (e) => {
    setmail(e.target.value);
  };
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      dispatch(auth(email, password, true));
      setpassword("");
      setpassword("");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Le due password non coincidono",
      });
      setpassword("");
      setpassword("");
      setConfirmPassword("");
      setConfirmPassword("");
    }
  };

  if (userToken) {
    return <Redirect to="/" />;
  }
  if (type === null) {
    return <Redirect to="/price"></Redirect>;
  }

  return (
    <div className="local-bootstrap">
      <div class="container h-100">
        <div class="row justify-content-sm-center h-100">
          <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
            <div class="text-center my-5">
              <img
                src="https://i.ibb.co/bQbnG8k/logo-es-br-40-512-512.png"
                alt="logo"
                width="100"
              />
            </div>
            <div class="card shadow-lg">
              <div class="card-body p-5">
                <h1 class="fs-4 card-title fw-bold mb-4">Registrazione</h1>
                <form
                  method="POST"
                  class="needs-validation"
                  novalidate=""
                  autocomplete="off"
                >
                  <div class="mb-3">
                    <label class="mb-2 text-muted" for="email">
                      E-Mail
                    </label>
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      value={email}
                      onChange={handleEmail}
                      required
                      autofocus
                    />
                    <div class="invalid-feedback">Email is invalid</div>
                  </div>

                  <div class="mb-3">
                    <div class="mb-2 w-100">
                      <label class="text-muted" for="password">
                        Password
                      </label>
                    </div>
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password"
                      value={password}
                      onChange={handlePassword}
                      required
                    />
                    <div class="invalid-feedback">Password is required</div>
                  </div>
                  <div class="mb-3">
                    <div class="mb-2 w-100">
                      <label class="text-muted" for="password">
                        Conferma Password
                      </label>
                    </div>
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password-confirm"
                      value={confirmPassword}
                      onChange={handleConfirmPassword}
                      required
                    />
                    <div class="invalid-feedback">Password is required</div>
                  </div>

                  <div class="d-flex align-items-center btn-log-fm">
                    <button type="submit" class="btn btn-primary" onClick={handleSubmit}>
                      Registrati
                    </button>
                  </div>
                  <div class="d-flex align-items-center">
                    <p className="auth-error mt-2">{error}</p>
                  </div>
                </form>
              </div>
            </div>
            <div class="text-center mt-5 text-muted">
              Copyright &copy; 2022 &mdash; Lo.Sa. Technology Solutions
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-auth">
        <form action="">
          <p>Email</p>
          <input
            type="email"
            value={email}
            onChange={handleEmail}
            placeholder="scrivi la tua mail"
          />
          <p>Password</p>
          <input
            type="password"
            value={password}
            onChange={handlePassword}
            placeholder="scrivi la tua password"
          />
          <p>Conferma Password</p>
          <input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPassword}
            placeholder="scrivi la tua password"
          />
          <button className="auth-btn-reg" onClick={handleSubmit}>
            Registrati
          </button>

          <p className="auth-error">{error}</p>
        </form>
      </div> */}
    </div>
  );
};
export default Auth;
