import { collection, getDocs, query, where } from "@firebase/firestore";
import { totSearch } from "./TotSearch";

export const arrGrattaSM = async (
  db,
  price,
  arrVenditeDay,
  arrChartSingleDay,
  arrChartVenditeDay
) => {
  // array per i gratta da
  let arrGratta = await getDocs(
    query(collection(db, `modelli`), where("vUnita", "==", price))
  );
  arrGratta = arrGratta.docs
    .map(async (d) => {
      let codiceM = d._document.data.value.mapValue.fields.codiceGratta.integerValue;
      let nome = d._document.data.value.mapValue.fields.nome.stringValue;
      let nBiglietti = await totSearch(db, codiceM, nome);
      return {
        codiceGratta: codiceM,
        nome: d._document.data.value.mapValue.fields.nome.stringValue,
        arrVenduto: arrVenditeDay.filter(
          (item) => item.nome === nome || item.nome.slice(9) === nome
        ),
        imgM: d._document.data.value.mapValue.fields.imgM.stringValue,
        nBiglietti: nBiglietti,
        nUnita: d._document.data.value.mapValue.fields.nUnita.integerValue,
        arrChartSingleDay: arrChartSingleDay.map((data, index) => {
          return {
            data: data,
            arrVendite: arrChartVenditeDay[index].filter(
              (item) => item.nome === nome || item.nome.slice(9) === nome
            ),
          };
        }),
      };
    })
    .values();

  return arrGratta;
};
