import db from "../../Firebase-config";
import { getDoc, doc, setDoc, collection, getDocs, deleteDoc } from "firebase/firestore";

const FETCH_SINGLE_MAG_START = "FETCH_SINGLE_MAG_START";
const FETCH_SINGLE_MAG_SUCCESS = "FETCH_SINGLE_MAG_SUCCESS";
const FETCH_SINGLE_MAG_FAIL = "FETCH_SINGLE_MAG_FAIL";

const DELETE_MAZZETTA_START = "DELETE_MAZZETTA_START";
const DELETE_MAZZETTA_SUCCESS = "DELETE_MAZZETTA_SUCCESS";
const DELETE_MAZZETTA_FAIL = "DELETE_MAZZETTA_FAIL";

const CHANGE_SINGLE_GRATTA_MAG_START = "CHANGE_SINGLE_GRATTA_MAG_START";
const CHANGE_SINGLE_GRATTA_MAG_SUCCESS = "CHANGE_SINGLE_GRATTA_MAG_SUCCESS";
const CHANGE_SINGLE_GRATTA_MAG_FAIL = "CHANGE_SINGLE_GRATTA_MAG_FAIL";
const AGG_UI_GRATTA = "AGG_UI_GRATTA";
const CLEAN_ARR_UI = "CLEAN_ARR_UI";

const PASS_DATA = "PASS_DATA";

export const fetchSingleMag = (codiceGratta, nome) => {
  return async (dispatch) => {
    dispatch(fetchSingleMagStart());
    try {
      // start del primo codice di prova
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      // trovo le referenze del db nello specifico nella mazzetta
      const addRef = doc(db, `${refMag[0]}.magazzino`, nome);
      const addRefV = doc(addRef, "mazzetta", "venduto");
      const addRefnV = doc(addRef, "mazzetta", "nonVenduto");
      //------

      let data = await getDocs(collection(addRefnV, `serie`));
      let dataV = await getDocs(collection(addRefV, `serie`));

      const arrSerieGratta = data.docs.map((x, index) => {
        return {
          nome: x._document.key.path.segments[10],
          arrSerie:
            x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
              (y) => y.stringValue
            ),
          arrSerieV: dataV.docs[
            index
          ]._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values.map(
            (y) => y.stringValue
          ),
        };
      });

      let nBiglietti = data.docs.map(
        (x) => x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.length
      );
      var lodash = require("lodash");
      nBiglietti = lodash.sum(nBiglietti);

      dispatch(fetchSingleMagSuccess(arrSerieGratta, nBiglietti));
    } catch (error) {
      dispatch(fetchSingleMagFail(error));
      console.log(error);
    }
  };
};

export const fetchSingleMagStart = () => {
  return {
    type: FETCH_SINGLE_MAG_START,
  };
};

export const fetchSingleMagSuccess = (arrSerieGratta, nBiglietti) => {
  return {
    type: FETCH_SINGLE_MAG_SUCCESS,
    arrSerieGratta: arrSerieGratta,
    nBiglietti: nBiglietti,
  };
};

export const fetchSingleMagFail = (error) => {
  return {
    type: FETCH_SINGLE_MAG_FAIL,
    error: error,
  };
};

export const deleteMazzetta = (serieM, nome) => {
  return async (dispatch, getState) => {
    dispatch(deleteMazzettaStart());
    try {
      //START CODICE
      // const nome = await getState().state.singleMagReducers.nome;
      // const codiceGratta = await getState().singleMagReducers.codiceGratta;

      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      // trovo le referenze del db nello specifico nella mazzetta
      const addRef = doc(db, `${refMag[0]}.magazzino`, nome);
      const addRefV = doc(addRef, "mazzetta", "venduto");
      const addRefnV = doc(addRef, "mazzetta", "nonVenduto");

      const addRefMV = doc(addRefV, "serie", serieM); // rif specifica colleiozione dove si trova il campo gratta  venduti
      const addRefM = doc(addRefnV, "serie", serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

      deleteDoc(addRefM);
      deleteDoc(addRefMV);
      //------
      let data = await getDocs(collection(addRefnV, `serie`));
      let dataV = await getDocs(collection(addRefV, `serie`));

      const arrSerieGratta = data.docs.map((x, index) => {
        return {
          nome: x._document.key.path.segments[10],
          arrSerie:
            x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
              (y) => y.stringValue
            ),
          arrSerieV: dataV.docs[
            index
          ]._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values.map(
            (y) => y.stringValue
          ),
        };
      });

      let nBiglietti = data.docs.map(
        (x) => x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.length
      );
      var lodash = require("lodash");
      nBiglietti = lodash.sum(nBiglietti);

      dispatch(fetchSingleMagSuccess(arrSerieGratta, nBiglietti));
      //____
      dispatch(deleteMazzettaSuccess());
    } catch (error) {
      dispatch(deleteMazzettaFail(error));
      console.log(error);
    }
  };
};

export const deleteMazzettaStart = () => {
  return {
    type: DELETE_MAZZETTA_START,
  };
};

export const deleteMazzettaSuccess = () => {
  return {
    type: DELETE_MAZZETTA_SUCCESS,
  };
};

export const deleteMazzettaFail = (error) => {
  return {
    type: DELETE_MAZZETTA_FAIL,
    error: error,
  };
};

export const changeSingleGrattaMag = (key, scelta, nome) => {
  return async (dispatch, getState) => {
    dispatch(changeSingleGrattaMagStart());
    try {
      //START CODICE
      // Splitto la stringa per ricavarne codice , serie e progressivo
      const codiceM = String(key).slice(0, 4);
      const serieM = String(key).slice(4, 11);
      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");

      const addRef = doc(db, `${refMag[0]}.magazzino`, nome); // riferimento del db nello specifici dellla mazzetta

      const addRefV = doc(addRef, "mazzetta", "venduto"); // rif biglietti  venduti
      const addRefMV = doc(addRefV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta  venduti

      const addRefnV = doc(addRef, "mazzetta", "nonVenduto"); // rif biglietti non ancora venduti
      const addRefM = doc(addRefnV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

      let data = await getDoc(addRefM);
      const arrMazz =
        data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values;
      let arrMazzetta = arrMazz.map((item) => item.stringValue);

      data = await getDoc(addRefMV);
      const arrMazzV =
        data._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values;
      let arrMazzettaV = arrMazzV.map((item) => item.stringValue);

      if (scelta === "venduto") {
        arrMazzetta = arrMazzetta.filter((item) => item !== key);
        arrMazzettaV.push(key);
        arrMazzettaV = arrMazzettaV.filter((item) => item !== "false");

        if (arrMazzetta.length === 0) {
          arrMazzetta.push("venduta");
        }

        setDoc(addRefM, { arrMazzetta });
        setDoc(addRefMV, { arrMazzettaV });
      } else {
        arrMazzettaV = arrMazzettaV.filter((item) => item !== key);
        arrMazzetta.push(key);
        arrMazzetta = arrMazzetta.filter((item) => item !== "venduta");

        if (arrMazzettaV.length === 0) {
          arrMazzettaV.push("false");
        }

        setDoc(addRefM, { arrMazzetta });
        setDoc(addRefMV, { arrMazzettaV });
      }

      if (arrMazzetta.includes("venduta")) {
        await dispatch(deleteMazzetta(codiceM + serieM, nome));
      }

      data = await getDocs(collection(addRefnV, `serie`));
      let dataV = await getDocs(collection(addRefV, "serie"));

      const arrSerieGratta = data.docs.map((x, index) => {
        return {
          nome: x._document.key.path.segments[10]
            ? x._document.key.path.segments[10]
            : x._document.key.path.segments[5],
          arrSerie:
            x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
              (y) => y.stringValue
            ),
          arrSerieV: dataV.docs[
            index
          ]._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values.map(
            (y) => y.stringValue
          ),
        };
      });

      let nBiglietti = data.docs.map(
        (x) => x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.length
      );
      var lodash = require("lodash");
      nBiglietti = lodash.sum(nBiglietti);

      //_____
      dispatch(aggiornaUiGratta(arrMazzetta, arrMazzettaV));
      dispatch(changeSingleGrattaMagSuccess(arrMazzetta, arrMazzettaV));
      dispatch(fetchSingleMagSuccess(arrSerieGratta, nBiglietti));
      dispatch(fetchSingleMagSuccess(arrSerieGratta, nBiglietti));
      if (arrMazzetta.includes("venduta")) {
        await dispatch(cleanArrUI());
      }
    } catch (error) {
      dispatch(changeSingleGrattaMagFail(error));
      console.log(error);
    }
  };
};

export const changeSingleGrattaMagStart = () => {
  return {
    type: CHANGE_SINGLE_GRATTA_MAG_START,
  };
};

export const changeSingleGrattaMagSuccess = (arrMazzetta, arrMazzettaV) => {
  return {
    type: CHANGE_SINGLE_GRATTA_MAG_SUCCESS,
    arrMazzetta: arrMazzetta,
    arrMazzettaV: arrMazzettaV,
  };
};

export const changeSingleGrattaMagFail = (error) => {
  return {
    type: CHANGE_SINGLE_GRATTA_MAG_FAIL,
    error: error,
  };
};

export const passData = (nome, codiceGratta, imgM) => {
  return {
    type: PASS_DATA,
    nome: nome,
    codiceGratta: codiceGratta,
    imgM: imgM,
  };
};

export const aggiornaUiGratta = (uiGratta, uiGrattaV) => {
  return {
    type: AGG_UI_GRATTA,
    uiGratta: uiGratta,
    uiGrattaV: uiGrattaV,
  };
};

export const cleanArrUI = () => {
  return {
    type: CLEAN_ARR_UI,
  };
};

export {
  FETCH_SINGLE_MAG_START,
  FETCH_SINGLE_MAG_SUCCESS,
  FETCH_SINGLE_MAG_FAIL,
  PASS_DATA,
  DELETE_MAZZETTA_FAIL,
  DELETE_MAZZETTA_SUCCESS,
  DELETE_MAZZETTA_START,
  CHANGE_SINGLE_GRATTA_MAG_FAIL,
  CHANGE_SINGLE_GRATTA_MAG_SUCCESS,
  CHANGE_SINGLE_GRATTA_MAG_START,
  AGG_UI_GRATTA,
  CLEAN_ARR_UI,
};
