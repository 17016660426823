import * as actionTypes from "../actions/handleSingleMag";

const initialState = {
  error: false,
  loading: false,
  arrSerieGratta: [],
  nome: "",
  codiceGratta: 0,
  nBiglietti: 0,
  arrMazzetta: [],
  uiGratta: [],
  uiGrattaV: [],
  imgM: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SINGLE_MAG_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_SINGLE_MAG_SUCCESS:
      return {
        ...state,
        //action
        arrSerieGratta: action.arrSerieGratta,
        nBiglietti: action.nBiglietti,
        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_SINGLE_MAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.DELETE_MAZZETTA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.DELETE_MAZZETTA_SUCCESS:
      return {
        ...state,
        //action

        //
        loading: false,
        error: false,
      };
    case actionTypes.DELETE_MAZZETTA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.CHANGE_SINGLE_GRATTA_MAG_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.CHANGE_SINGLE_GRATTA_MAG_SUCCESS:
      return {
        ...state,
        //action
        arrMazzetta: action.arrMazzetta,
        //
        loading: false,
        error: false,
      };
    case actionTypes.CHANGE_SINGLE_GRATTA_MAG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.PASS_DATA:
      return {
        ...state,
        nome: action.nome,
        codiceGratta: action.codiceGratta,
        imgM: action.imgM,
      };
    case actionTypes.AGG_UI_GRATTA:
      return {
        ...state,
        uiGratta: action.uiGratta,
        uiGrattaV: action.uiGrattaV,
      };
    case actionTypes.CLEAN_ARR_UI:
      return {
        ...state,
        uiGratta: [],
        uiGrattaV: [],
      };

    default:
      return state;
  }
};

export default reducer;
