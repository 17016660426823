import "../styles/SpinnerCarrello.css";

const SpinnerCarrello = () => {
  return (
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default SpinnerCarrello;
