import "../styles/SpinnerTotG.css";

const Spinner = () => {
  return (
    <div class="item">
      <i class="loader --1"></i>
    </div>
  );
};

export default Spinner;
