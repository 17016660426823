import React from "react";
import { useState } from "react";
import * as BiIcons from "react-icons/bi";
import { useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import "../styles/StampaVenditeGiornaliere.css";

function StampaVenditeGiornaliere(props) {
  const [input, setInput] = useState("");
  const from = props.location.state.from;
  const userToken = useSelector((state) => state.authReducer.token);

  let arrStampaVenditeDay = useSelector(
    (state) => state.statisticheReducers.arrStampaVenditeDay
  );
  const arrVenditeDay = useSelector((state) => state.homeReducers.arrVenditeDay);
  if (from === "home") {
    arrStampaVenditeDay = arrVenditeDay;
  }

  const handleInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const rederArrFilter = () => {
    return (
      arrStampaVenditeDay
        // eslint-disable-next-line array-callback-return
        .filter((f) => {
          if (input === "") {
            return f;
          } else if (
            f.nome.toLowerCase().includes(input.toLowerCase()) ||
            f.key.toLowerCase().includes(input.toLowerCase()) ||
            String(f.vUnita).toLowerCase().includes(input.toLowerCase())
          ) {
            return f;
          }
        })
        .map((x) => {
          return (
            <div className="result-stampa">
              <div className="col-res">
                <span>{x.nome}</span>
              </div>
              <div className="col-res">
                <span>{x.key}</span>
              </div>
              <div className="col-res">
                <span>{x.vUnita}€</span>
              </div>
            </div>
          );
        })
    );
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="stampa-vendite-container">
        <form action="">
          <input
            type="text"
            className="input-stampa"
            placeholder="inserisci il criterio di ricerca"
            onChange={handleInput}
            value={input}
          />
          <span>
            <BiIcons.BiSearchAlt />
          </span>
        </form>
        <div className="stampa">{rederArrFilter()}</div>
      </div>
    </>
  );
}

export default StampaVenditeGiornaliere;
