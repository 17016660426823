import React, { useEffect, useState } from "react";
import { errorReset } from "../store/actions/handleHome";
import { errorResetCG } from "../store/actions/handleCaricaGratta";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import { insertGratta, resetSerieEsiste } from "../store/actions/handleCaricaGratta";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { stateChange } from "../functions/stateChange";
import soudError from "../sound/error.wav";
import Navbar from "../components/Navbar";
import "../styles/Price.css";
import "../styles/Price.scss";
import {
  caricaGrattaTourStart,
  introCaricaGrattaTourStart,
} from "../store/actions/handleTour";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

function CaricaGratta() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [input, setInput] = useState("");

  const uiList = useSelector((state) => state.caricaGrattaReducers.uiList);
  const loading = useSelector((state) => state.caricaGrattaReducers.loading);
  const error = useSelector((state) => state.caricaGrattaReducers.error);
  const serieEsiste = useSelector((state) => state.caricaGrattaReducers.serieEsiste);

  useEffect(() => {
    dispatch(introCaricaGrattaTourStart());
  }, [dispatch]);

  const audio = new Audio(soudError);
  audio.loop = false;
  const playError = () => {
    audio.loop = false;
    audio.play();
  };

  // MAZZETTA è  FALSE --- SINGOLO è  TRUE

  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);

  const notifyError = (err) =>
    toast.error(err, {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(errorResetCG());
    }
  }, [dispatch, token]);

  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  //bottone CARICA
  const onClickCarica = (e) => {
    e.preventDefault();

    if (input.trim() === "") {
      audio.loop = false;
      audio.play();
      toast.error("non hai inserito nessun codice", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setInput("");
      dispatch(errorResetCG());
      return;
    }

    if (input.length < 14) {
      audio.loop = false;
      audio.play();
      toast.error("hai inserito un codice errato", {
        position: "top-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setInput("");
      dispatch(errorResetCG());
      return;
    }

    dispatch(insertGratta(input.slice(0, 14), selected));

    dispatch(errorResetCG());
    setInput("");
    dispatch(caricaGrattaTourStart());
    return;
  };

  // const rederFakeData = () => {
  //   return uiList.map((item, index) => {
  //     return (
  //       <div className="load-succes" key={index}>
  //         <div className="local-bootstrap">
  //           <div class="card" style={{ width: "18rem" }}>
  //             <img src={item.imgSrc} className="card-img-top img-cg" alt="..." />
  //             <div class="card-body">
  //               <h5 class="card-title">
  //                 SERIE : {item.id}
  //                 <span className="icon-check"> {<BsIcons.BsCheckCircleFill />} </span>
  //               </h5>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });
  // };
  const rederData = () => {
    return uiList.map((item, index) => {
      return (
        <Card sx={{ maxWidth: 345, margin: "20px" }}>
          <CardActionArea
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              padding: "10px",
              cursor: "auto",
            }}
          >
            <CardMedia
              component="img"
              height="100"
              image={item.imgSrc}
              alt=""
              sx={{ width: "80px" }}
            />
            <CardContent>
              <Typography gutterBottom variant="h7" component="div">
                SERIE : {item.id}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    });
  };

  const handelResetSerieEsiste = () => {
    audio.loop = false;
    audio.play();
    toast.error("Mazzetta o Biglietto già caricati ", {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    dispatch(resetSerieEsiste(false));
    dispatch(errorResetCG());
    return;
  };
  const alterShow = () => {
    dispatch(errorResetCG());
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}

      <div className="caricagratta">
        <div className="mobile-viewer">
          <h2>Non è possibile caricare i biglietti da mobile</h2>
        </div>
        <div className="container-form">
          <div className="head-form">
            <form action="" onSubmit={onClickCarica}>
              <div className="local-bootstrap">
                <div class="search-cg">
                  <MdIcons.MdBarcodeReader className="icon-bar-cg" id="icon-br-cg" />
                  <BiIcons.BiBarcodeReader className="icon-bar-cg" id="icon-brc-cg" />
                  <input
                    type="text"
                    className="form-control input-icon-br"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={input.slice(0, 14)}
                    onChange={(e) => setInput(e.target.value)}
                    inputMode="none"
                    placeholder="Inserisci il codice della mazzetta o del Gratta&vinci che vuoi caricare"
                    id="input-text"
                    autoComplete="off"
                  />
                </div>
              </div>
              {/* <input
                value={input.slice(0, 14)}
                onChange={(e) => setInput(e.target.value)}
                type="text"
                placeholder="Inserisci il codice della mazzetta o del Gratta&vinci che vuoi caricare"
              /> */}
            </form>
            <button
              className={selected ? "btn-mag mazzetta" : "btn-mag active mazzetta"}
              onClick={() => setSelected(false)}
            >
              Mazzetta
            </button>
            <button
              className={!selected ? "btn-mag singolo" : "btn-mag active singolo"}
              onClick={() => setSelected(true)}
            >
              Singolo
            </button>
          </div>
          <div className="list-gratta-caricati">
            {serieEsiste ? (
              handelResetSerieEsiste()
            ) : loading ? (
              <Spinner />
            ) : error ? (
              (playError(), notifyError(error), alterShow())
            ) : (
              rederData()
            )}
          </div>
        </div>
        <ToastContainer
          position="top-letf"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          color={"white"}
        />
      </div>
    </>
  );
}

export default CaricaGratta;
