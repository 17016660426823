import * as actionTypes from "../actions/handleStatistiche";

const initialState = {
  error: false,
  loading: false,
  listGrattaArr: {},
  arrStampaVenditeDay: [],
  arrChart: [],
  dataInizioStatistiche: null,
  dataFineStatistiche: null,
  listGratta: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STATISTICHE_G_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_STATISTICHE_G_SUCCESS:
      return {
        ...state,
        //action
        listGrattaArr: action.listGrattaArr,
        arrStampaVenditeDay: action.arrStampaVenditeDay,
        listGratta: action.listGratta,

        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_STATISTICHE_G_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.FETCH_STATISTICHE_S_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_STATISTICHE_S_SUCCESS:
      return {
        ...state,
        // //action
        listGrattaArr: action.listGrattaArr,
        arrChart: action.arrChart,
        dataInizioStatistiche: action.dataInizioStatistiche,
        dataFineStatistiche: action.dataFineStatistiche,
        listGratta: action.listGratta,

        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_STATISTICHE_S_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
