export const caricaGrattaTour = [
  {
    target: `.list-gratta-caricati`,
    content: `ottimo come vedi hai caricato l'intera mazzetta`,
    disableBeacon: true,
  },
  {
    target: `.card`,
    content: `si esattamene questa, come puoi vedere in questo simpatico riepilogo possiamo vedere cosa è successo`,
  },
  {
    target: `.home-mini`,
    content: `Adesso premi il tasto Home che vedi selezionato per tornare alla pagina principale cosi 
    simuleremo una vendita`,
  },
];
