import db from "../../Firebase-config";

import { arrGrattaMag } from "../../functions/ArrGrattaMag";
import { getType } from "../../functions/getArrayModel";

const FETCH_DATA_START = "FETCH_DATA_START";
const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
const FETCH_DATA_FAIL = "FETCH_DATA_FAIL";

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataStart());
    try {
      // start del primo codice di prova
      const listGratta = (await getType())["type"];
      let listGrattaArr = {};

      for await (let x of listGratta) {
        listGrattaArr[x] = await arrGrattaMag(db, x);
      }
      dispatch(fetchDataSuccess(listGrattaArr, listGratta));
    } catch (error) {
      dispatch(fetchDataFail(error));
      console.log(error);
    }
  };
};

export const fetchDataStart = () => {
  return {
    type: FETCH_DATA_START,
  };
};

export const fetchDataSuccess = (listGrattaArr, listGratta) => {
  return {
    type: FETCH_DATA_SUCCESS,
    listGrattaArr,
    listGratta,
  };
};

export const fetchDataFail = (error) => {
  return {
    type: FETCH_DATA_FAIL,
    error: error,
  };
};

export { FETCH_DATA_START, FETCH_DATA_SUCCESS, FETCH_DATA_FAIL };
