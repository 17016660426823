import * as actionTypes from "../actions/handleTour";

const initialState = {
  startIntroTour: true,
  startIntroCaricaGratta: false,
  startCaricaGrattaTour: false,
  startHomeTour: false,
  startSearchBarTour: false,
  startIncassaTour: false,
  startStornoTour: false,
  startAfterStornoTour: false,
  startIntroStatsTour: false,
  startStatsTour: false,
  loading: false,
  error: false,
  start: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOUR_SELECTOR_START:
      return {
        ...state,

        loading: true,
        error: false,
      };
    case actionTypes.TOUR_SELECTOR_SUCCESS:
      return {
        ...state,
        start: action.start,
        //
        loading: false,
        error: false,
      };
    case actionTypes.TOUR_SELECTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.GET_TOUR_START:
      return {
        ...state,

        loading: true,
        error: false,
      };
    case actionTypes.GET_TOUR_SUCCESS:
      return {
        ...state,
        start: action.start,
        //
        loading: false,
        error: false,
      };
    case actionTypes.GET_TOUR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.INTRO_TOUR_START:
      return {
        ...state,
        startIntroTour: true,
      };

    case actionTypes.INTRO_CARICA_GRATTA_TOUR_START:
      return {
        ...state,
        startIntroTour: false,
        startIntroCaricaGratta: true,
      };

    case actionTypes.CARICA_GRATTA_TOUR_START:
      return {
        ...state,
        startIntroCaricaGratta: false,
        startCaricaGrattaTour: true,
      };

    case actionTypes.HOME_TOUR_START:
      return {
        ...state,
        startCaricaGrattaTour: false,
        startHomeTour: true,
      };

    case actionTypes.HOME_SEARCH_BAR_TOUR_START:
      return {
        ...state,
        startHomeTour: false,
        startSearchBarTour: true,
      };
    case actionTypes.INCASSA_TOUR_START:
      return {
        ...state,
        startSearchBarTour: false,
        startIncassaTour: true,
      };
    case actionTypes.STORNO_TOUR_START:
      return {
        ...state,
        startIncassaTour: false,
        startStornoTour: true,
      };
    case actionTypes.AFTER_STORNO_TOUR_START:
      return {
        ...state,
        startStornoTour: false,
        startAfterStornoTour: true,
      };
    case actionTypes.INTRO_STATS_TOUR_START:
      return {
        ...state,
        startAfterStornoTour: false,
        startIntroStatsTour: true,
      };
    case actionTypes.STATS_TOUR_START:
      return {
        ...state,
        startIntroStatsTour: false,
        startStatsTour: true,
      };

    default:
      return state;
  }
};

export default reducer;
