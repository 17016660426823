import { collection, doc, getDocs } from "firebase/firestore";

export const totSearch = async (db, codiceM, nome) => {
  const email = localStorage.getItem("email");
  const refMag = email.split("@");

  const addRef = doc(db, `${refMag[0]}.magazzino`, nome);
  const addRefnV = doc(addRef, "mazzetta", "nonVenduto");
  let dataTot = await getDocs(collection(addRefnV, `serie`));
  let nBiglietti = dataTot.docs.map(
    (x) => x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.length
  );
  var lodash = require("lodash");
  nBiglietti = lodash.sum(nBiglietti);
  return nBiglietti;
  //----------------------------------------------------------------
};
