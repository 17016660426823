export const incassaTour = [
  {
    target: `.tot-giorno-container`,
    content: `Bravissimo hai incassato il tuo primo carrello come vedi hai incrementato il valore del totale giornaliero`,
    disableBeacon: true,
  },
  {
    target: `.storno`,
    content: `Adesso passiamo allo storno. Mettiamo che il cliente in questione non vuole più il Gratta&Vinci 
    cosa fai ?  Ma lo storni ovviamnete , quindi adesso premi il tasto storno e spara il codice nell'apposita casella `,
  },
];
