export const introCaricaGrattaTour = [
  {
    target: `.navbar-toggle`,
    content: `Eccoci qua, in questa pagina potrai caricare tutti i Gratta&Vinci che hai comprato
     in cosi poco tempo che neanche ti sembrerà vero `,
    disableBeacon: true,
  },
  {
    target: `.navbar-toggle`,
    content: `Come prima cosa devi selezionare la modalità di carica,
     MAZZETTA o SINGOLO, di defalut è impostata MAZZETTA perchè è la modalità più comune ed utilizzata  `,
  },
  {
    target: `.mazzetta`,
    content: `Con questa modalità inserendo qualsiasi Gratta&Vinci della mazzetta caricherai l'intera mazzetta`,
  },
  {
    target: `.singolo`,
    content: `Con questa modalità caricherai solo il Gratta&Vinci della mazzetta che hai inserito`,
  },
  {
    target: `.search-cg`,
    content: `Dai cominciamo, inserisci un codice di un Gratta&Vinci della mazzetta che vuoi caricare `,
  },
];
