import { doc, getDoc, setDoc } from "firebase/firestore";
import db from "../Firebase-config";
export const LoadModel = async (formsData) => {
  const addRefModel = doc(db, "modelli", formsData.codiceGratta); // rif biglietti non ancora venduti
  let dataModel = await getDoc(addRefModel);
  const modelExsist = dataModel.exists();
  if (modelExsist === false) {
    setDoc(addRefModel, {
      nome: formsData.nome,
      codiceGratta: Number(formsData.codiceGratta),
      imgC: formsData.imgC,
      imgM: formsData.imgC,
      vUnita: Number(formsData.vUnita),
      nUnita: Number(formsData.nUnita),
    });
  }

  return !modelExsist;
  //----------------------------------------------------------------
};
