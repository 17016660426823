import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as TiIcons from "react-icons/ti";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";

export const data = {
  title: "FAQ (Domande Frequenti)",
  rows: [
    {
      title: "Come si carica una Mazzetta ?",
      content: (
        <p>
          Caricare una Mazzetta è davvero semplicissimo recati nel menu alla voce 'Carica
          Gratta&Vinci' <FaIcons.FaCartPlus /> ed inserisci tramite pistola barcode o
          tramite tastiera un codice di un qualsiasi biglietto di quella mazzetta,
          automaticamente il software riconoscerà la tipologia del biglietto è caricherà
          l'intera mazzetta nel tuo magazzino in un attimo, apparira un rettangolo con
          l'immagine del Gratta&Vinci ed il numero della serie caricata
        </p>
      ),
    },
    {
      title: "Come si scarica un Gratta&Vinci?",
      content: (
        <p>
          Come prima cosa assicurati di aver caricato la mazzetta del biglietto che vuoi
          scaricare nel tuo magazzino, dopo aver fatto questo vai nel menu alla voce
          'Home'
          <AiIcons.AiFillHome /> ed inserisci tramite pistola/barcode o da tastiera il
          codice a barre del biglietto che vuoi scaricare, automaticamente il software
          riconoscerà il biglietto e lo aggiungerà al tuo carrello
        </p>
      ),
    },
    {
      title:
        "Per sbaglio ho scaricato un biglietto che adesso ho ancora in magazzino cosa devo fare ? ",
      content: (
        <p>
          Non preoccuparti, stai tranmquillo, è semplicissimo stornare un bilgietto, dopo
          averlo venduto hai 3 opzioni: <br /> 1- Se non hai ancora incassato tramite il
          tasto 'Incassa' o premendo 'F8' vedrai ancora il tuo biglietto bel carrello e
          potrai premere il tasto <TiIcons.TiDelete /> automaticamente verra eliminato dal
          carrello. <br />
          2- Se hai già premuto il tasto 'Incassa' o 'F8' allora dovrai usare la nostra
          speciale funzione 'Storna Biglietto' premi l'apposito tasto con l'icona{" "}
          <RiIcons.RiDeleteBackFill /> e si aprira una finistra di dialogo ( PopUp) dove
          inseirai il codice a barre del biglietto che vuoi stornare ed automaticamente
          verra stornato dal magazzino e verrà creata nel carrello una vendita 'storno'
          che corrisponderà a quest'ultimo. <br />
          3- Hai anche un altra opzione che però non va ad interfierire con il Totale
          Giornaliero ed è quella di eliminare direttametne il biglietto dal tuo magazzino
          andando nell'apposita voce al menu 'Magazzino' <FiIcons.FiSettings />{" "}
          selezionando l'immagine della tipologia di biglietto che vuoi andare a
          modificare ti si aprira una pagina dove potrai andare a modificare il magazzino
          di quella specifica mazzetta senza che le tue modifiche interferiscano con il
          Totale giornaliero.
        </p>
      ),
    },
    {
      title:
        "Volevo controllare se una mazzetta era stata venduta correttamente e l'ho cercata nel magazzino ma non la trovo, perchè?",
      content: (
        <p>
          {" "}
          Le mazzette già completamente vendute vengono eliminate automaticamente dal tuo
          magazzino in modo che sia sempre snello limpido e veloce, se vuoi controlalre se
          la tua mazzetta è stata venduta correttamete devi andare alla voce di menu
          'Statistiche' <MdIcons.MdQueryStats /> e cliccare sul tasto ' Mostra Vendite'{" "}
          <AiIcons.AiOutlineFileSearch /> dello specifoco giorno nel quale hai venduto
          quella mazzetta o quel biglietto della mazzetta, ti si aprira una schermata
          nella quale potrai consultare tutte le venditè di quel giorno filtrandole anche
          dall'apposita barra di ricerca
        </p>
      ),
    },
    {
      title:
        "Ho scaircato il biglietto ed è presente nel carrello ma il totale giornalierto non è stato incremenato ",
      content: (
        <p>
          Corretto !! Non hai fatto nulla di sbagliato adesso devi solo premere il tasto
          'Incassa' o 'F8' ed il tuo carrello verrà incassato con successo di conseguenza
          anche il tuo 'Totale Giornaliero' verrà incremenato con successo
        </p>
      ),
    },
    {
      title: "Ho bisgno di assistenza cosa devo fare ?",
      content: (
        <p>
          Apri un tiket nella apposita sezione <MdIcons.MdOutlineLiveHelp /> e verrai
          subito contattato da un nostro consulente che si metterà a tua completa
          disposizone per risolvere ogni tuo problema
        </p>
      ),
    },
    {
      title: "Posso scaricare una mazzetta intera con un solo click ?",
      content: (
        <p>
          Niente di più semplice, i nostri sviluppatori hanno pensato anche a questo,
          nella tua home è presente un tasto 'scarica mmazzetta'{" "}
          <MdIcons.MdOutlineDownloadDone /> , una volta premuto si aprirà un popUp nel
          quale potrai inserire qualsiasi codice di quella mazzatta e verrà aggiunta al
          tuo carrello l'interà mazzatta
        </p>
      ),
    },
    {
      title: "Come funziona la proposta d'ordine ?",
      content: (
        <p>
          La proposta d'ordine è uno strumento importante che ti aiuta ad affettura il tuo
          ordine di Gratta&Vinci, viene calcolata in base alla vendita media giornaliera
          (VMG) della sezione che hai scelto. <br />
          Esempio : se nella senzione Statistche hai scelto di consultare quelle
          'SETTIMANALI' allora la tua vendita giornaliera sarà calcolata nei 7 giorni
          precedenti a quello selezionato, ( abbiamo scelto di non calcolare quello
          corrente poichè non essendo completamente terminato ci sarebbe una vendita non
          compelta e di conseugunza un dato non vero ). Questo tipo di statische
          'SETTIMANALI' e consigliato dai nostri esperti nei periodi di maggior afflusso
          perchè essendo più breve riesce a ad essere più accurato nelle settimane dove
          hai dei piccchi di vendite.
          <br /> Se invece dovessi sccelgiere di consultare le 'MENSILI' la VMG verrà
          calcolata nei 30 giorni precedenti a quello selezionato. Questa è la soluzione
          miliore che andrebbe usata in una situzione di vendita regolare perchè essendo
          calcolato in un periodo di 30 gg riesce ad ottenre una curva di vendita più
          ampia ed omogenea.
          <br /> Il nostro algoritomo di proposta è impostato in maniera da calcolare il
          fabbisgno di biglietti che sarà necessraio fino allo stesso giorno in cui hai
          affettutato l'ordine.
        </p>
      ),
    },
    {
      title: "Posso fidarmi della proposta d'ordine ?",
      content: (
        <p>
          Oviamente si !! Certo che puoi fidarti, ma devi tenere bene a mente due cose
          fondamentali. <br />
          1- il tuo magazzino deve essere caricato in maniera corretta , ovvero che tutti
          le tue mazzette devono essere caricate in magazzino ed i biglietti scaricati ad
          ogni vendità. <br />
          2- Ci sono delle variabili che vanno oltre la previsione statistica e che quindi
          dovrai tenere tu in consideraizone, di cosa parlo ? Clienti non abituali ,
          vendite occasionali ma grosse.
        </p>
      ),
    },
  ],
};
