import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { stateChange } from "../functions/stateChange";
import "../styles/Admin.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { LoadModel } from "../functions/LoadModel";
import { fetchData } from "../store/actions/handleMagazzino";
import { errorReset } from "../store/actions/handleHome";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";

function Admin() {
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const dispatch = useDispatch();

  const listGrattaArr = useSelector((state) => state.magazzinoReducers.listGrattaArr);

  let arrGratta = [];
  for (const key in listGrattaArr) {
    arrGratta = arrGratta.concat(listGrattaArr[key]);
  }

  console.log(arrGratta);
  const loading = useSelector((state) => state.magazzinoReducers.loading);
  const [formData, setFormData] = useState({
    nome: "",
    codiceGratta: 0,
    imgC: "",
    imgM: "",
    vUnita: 0,
    nUnita: 0,
  });

  useEffect(() => {
    if (token && email === "salvo.lorello@gmail.com") {
      stateChange();
      dispatch(errorReset());
      dispatch(fetchData());
    }
  }, [dispatch, email, token]);

  if (!token || email !== "salvo.lorello@gmail.com") {
    return <Redirect to="/"></Redirect>;
  }
  const submitForm = async (e) => {
    e.preventDefault();
    if (formData.nome.trim() === "") {
      Swal.fire({
        title: "Errore",
        text: "il campo 'nome' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.codiceGratta === 0) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'codiceGratta' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.imgC.trim() === "") {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Immagine' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.nUnita === 0) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Numero unità' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    if (formData.vUnita === 0) {
      Swal.fire({
        title: "Errore",
        text: "il campo 'Valore unità' è vuoto o non valido",
        icon: "warning",
      });
      return;
    }
    const result = await LoadModel(formData);
    console.log(result);

    if (!result) {
      Swal.fire({
        title: "Errore",
        text: "Modello non caricato",
        icon: "warning",
      });
      setFormData({
        nome: "",
        codiceGratta: 0,
        imgC: "",
        imgM: "",
        vUnita: 0,
        nUnita: 0,
      });
      return;
    }
    Swal.fire({
      title: "Succes",
      text: "Modello caricato",
      icon: "Success",
    });
    setFormData({ nome: "", codiceGratta: 0, imgC: "", imgM: "", vUnita: 0, nUnita: 0 });
  };

  const rednerSelectName = () => {
    return arrGratta.map((item) => {
      return <option value={item.nome}>{item.nome}</option>;
    });
  };
  const rednerSelectImg = () => {
    return arrGratta.map((item) => {
      return <option value={item.imgM}>{item.nome}</option>;
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div class="local-bootstrap">
      <div className="admin">
        <form className="form-admin">
          <div class="form-group mt-2">
            <select
              class="form-select"
              aria-label="Disabled select example"
              onChange={(e) => setFormData({ ...formData, nome: e.target.value })}
            >
              <option selected>SELEZIONA IL NOME</option>
              {rednerSelectName()}
            </select>
          </div>
          <div class="form-group mt-3">
            <label for="codice-gratta">Codice Gratta&Vinci</label>
            <input
              type="number"
              class="form-control"
              id="codice-gratta"
              name="codiceGratta"
              placeholder="Codice Gratta&Vinci"
              value={formData.codiceGratta}
              onChange={(e) => setFormData({ ...formData, codiceGratta: e.target.value })}
            />
          </div>
          <div class="form-group mt-3">
            <label for="img-gratta">Immagine Gratta&Vinci</label>

            <select
              class="form-select"
              aria-label="Disabled select example"
              onChange={(e) => setFormData({ ...formData, imgC: e.target.value })}
            >
              <option selected>SELEZIONA L'IMMAGINE</option>
              {rednerSelectImg()}
            </select>
          </div>
          <div class="form-group mt-3">
            <label for="n-unita">Numero Unità</label>
            <input
              type="number"
              class="form-control"
              id="n-unita"
              name="nUnita"
              placeholder="Numero Unità"
              value={formData.nUnita}
              onChange={(e) => setFormData({ ...formData, nUnita: e.target.value })}
            />
          </div>
          <div class="form-group mt-3">
            <label for="v-unita">Valore Unità</label>
            <input
              type="number"
              class="form-control"
              id="v-unita"
              name="vUnita"
              placeholder="0"
              value={formData.vUnita}
              onChange={(e) => setFormData({ ...formData, vUnita: e.target.value })}
            />
          </div>
          <div className="btn-container">
            <button type="submit" class="btn btn-primary" onClick={submitForm}>
              Carica
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Admin;
