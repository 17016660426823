import { collection, getDocs, query, where } from "@firebase/firestore";
import { totSearch } from "./TotSearch";

export const arrGrattaG = async (db, price, arrVenditeDay) => {
  // array per i gratta da 'importo'
  let arrGratta = await getDocs(
    query(collection(db, `modelli`), where("vUnita", "==", price))
  );

  arrGratta = arrGratta.docs
    .map(async (d) => {
      // cerca il totale dei biglietti nel magazzino (GIACENZA)
      let nBiglietti = await totSearch(
        db,
        d._document.data.value.mapValue.fields.codiceGratta.integerValue,
        d._document.data.value.mapValue.fields.nome.stringValue
      ); //fine

      return {
        codiceGratta: d._document.data.value.mapValue.fields.codiceGratta.integerValue,

        nome: d._document.data.value.mapValue.fields.nome.stringValue,
        arrVenduto: arrVenditeDay.filter(
          (item) =>
            item.nome === d._document.data.value.mapValue.fields.nome.stringValue ||
            item.nome.slice(9) === d._document.data.value.mapValue.fields.nome.stringValue
        ),
        imgM: d._document.data.value.mapValue.fields.imgM.stringValue,
        nBiglietti: nBiglietti,
      };
    })
    .values();

  return arrGratta;
};
