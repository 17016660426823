export const introTour = [
  {
    target: `.navbar-toggle`,
    content: `Benvenuto in EasyScratch il gestionale per Gratta&Vinci che ti aiutera a gestire, i Gratta&Vinci,
     in maniera semplice ed efficace.  Dai Iniziamo questo tour`,
    disableBeacon: true,
  },
  {
    target: `.data-pikers`,
    content: `Con questo tasto potrai scegliere la data in cui scaricare i Gratta&Vinci o scegliere le statistche
     che vuoi visualizzare nell'apposita sezione`,
  },
  {
    target: `.search`,
    content: `In questa bella barra davvero affascinante tramite il BarCode che ti abbiamo regalato 
    potrai scaricare i Gratta&Vinci e visualizzarli nel carrello che si trova qui sotto, ma lo vedremo dopo`,
  },

  {
    target: `.titolo-carrello p`,
    content: `Dopo aver scaricato un Gratta%Vinci, dall'apposita barra, verrà incremnetato il carrello 
    e qui troverai il totale del carrello `,
  },
  {
    target: `.btn-carrello`,
    content: `che potrai incassare cliccando questo tasto andando ad incrementare il totale giornaliero `,
  },
  {
    target: `.tot-giorno-container`,
    content: `Esattamente !!, Questo totale rappresenta il valore in € dei Gratta&Vinci venduti nel giorno
     da te selezionato  `,
  },
  {
    target: `.mostra`,
    content: `Premendo questo tasto potrai vedere nel dettaglio le vendite del giorno corrente`,
  },
  {
    target: `.storno`,
    content: `Con questo invece potrai Stornare un biglietto già venduto , magari venduto per sbaglio oppure 
    perche un cliente ci ha ripensato  `,
  },
  {
    target: `.scarica-mazzetta-container`,
    content: `Ti sarà capitato sicuramnente di vendere una mazzetta intera, e pensi che ti avremmo fatto scaricare 
    i Gratta&Vinci uno per uno ? Ma dai non scherziamo.. premendo questo tasto scaricherai tutta la mazzetta
     con un solo click`,
  },
  {
    target: `.cg-mini`,
    content: `Ma adesso cominciamo dai facciamo una prova, andiamo a caricare la nostra 
    prima mazzetta di Gratta&Vinci. Premi il tasto del carrello nel menu laterale`,
  },
];
