import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import DatePicker from "react-datepicker";
import "../styles/Statistiche.css";
import HeaderCardGroup from "../components/HeaderCardGroup";
import { fetchStatisticheG, fetchStatisticheS } from "../store/actions/handleStatistiche";
import { useDispatch } from "react-redux";
import StatsCard from "../components/StatsCard";
import { useSelector } from "react-redux";
import { stateChange } from "../functions/stateChange";
import Spinner from "../components/Spinner";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { introStatsTourStart } from "../store/actions/handleTour";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Chart from "react-apexcharts";
import { styled } from "@mui/material/styles";
import { DataTranform } from "../functions/DateTranform";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function Statistiche() {
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);

  const [inputDal, setInputDal] = useState(new Date());
  const [inputAl, setInputAl] = useState(new Date());
  const [disbaleButton, setDisbaleButton] = useState(false);
  const [arrStats, setArrStats] = useState(2);

  const dispatch = useDispatch();
  const listGrattaArr = useSelector((state) => state.statisticheReducers.listGrattaArr);
  const listGratta = useSelector((state) => state.statisticheReducers.listGratta);
  const loading = useSelector((state) => state.statisticheReducers.loading);
  const arrChart = useSelector((state) => state.statisticheReducers.arrChart);
  const arrStampaVenditeDay = useSelector(
    (state) => state.statisticheReducers.arrStampaVenditeDay
  );

  const arrPropostaPDF = [];

  var lodash = require("lodash");

  useEffect(() => {
    if (token) {
      stateChange();
      dispatch(fetchStatisticheG(DataTranform(inputAl) ));
      dispatch(introStatsTourStart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, token]);

  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }

  const handleDateSelect = async (date, type) => {
    if (type) {
      const date1 = new Date(date);
      const date2 = new Date(inputDal);
      if (date1.getTime() < date2.getTime()) {
        setInputDal(date);
      }
      setInputAl(date);
    } else {
      setInputDal(date);
    }
  };

  const dateFormater = (date) => {
    return date.toLocaleDateString().replaceAll("/", "-");
  };

  const getDiffDays = () => {
    //  calcale il numero di giorni tra le due date
    const date1 = new Date(inputDal);
    const date2 = new Date(inputAl);
    const diffTime = Math.abs(date1 - date2);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const onClickSearch = (e) => {
    e.preventDefault();

    if (dateFormater(inputDal) === dateFormater(inputAl)) {
      setDisbaleButton(false);
      dispatch(fetchStatisticheG(DataTranform(inputAl)));
      setArrStats(2);
    } else {
      setDisbaleButton(true);
      dispatch(fetchStatisticheS(DataTranform(inputAl), getDiffDays()));
      setArrStats(3);
    }
  };

  const renderStats = (n) => {
    switch (n) {
      case 2:
        return renderGioraliero("ND");
      case 3:
        return renderSettimanali(getDiffDays());
      default:
        break;
    }
  };

  const renderGioraliero = (day) => {
    if (day !== "ND") {
      day = getDiffDays();
    }

    const arrTotDay = arrStampaVenditeDay.map((item) => item.vUnita);
    const totDay = lodash.sum(arrTotDay);

    return (
      <div>
        <div className="stat-general">
          <p> Totale : {totDay} € </p>
          <p> Aggio : {(totDay * 8) / 100}€</p>
        </div>
        {listGratta.map((item) => {
          return (
            <div>
              <HeaderCardGroup title={item + "€"} />
              {renderStatsCard(listGrattaArr[item], day, item)}
            </div>
          );
        })}
      </div>
    );
  };
  const renderSettimanali = (day) => {
    let arrTotal = arrChart.map((item) => item[1]);
    const options = {
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: arrChart.map((item) => item[0]).slice(1),
        },
      },
      animations: {
        enabled: false,
      },

      series: [
        {
          name: "Totale Venduto",
          data: arrChart.map((item) => item[1]).slice(1),
        },
      ],
    };
    arrTotal.shift();
    let totWeek = lodash.sum(arrTotal);
    return (
      <div>
        <div className="stat-general">
          <p> Totale : {totWeek} € </p>
          <p> Media vendite : {(totWeek / day).toFixed(1)} €</p>
          <p> Aggio : {(totWeek * 8) / 100}€</p>
        </div>
        <div style={{ zIndex: 2 }} className="graf-stat">
          <Chart
            options={options.options}
            series={options.series}
            type="line"
            width="100%"
            height="120"
          />
        </div>

        {listGratta.map((item) => {
          return (
            <div>
              <HeaderCardGroup title={item + "€"} />
              {renderStatsCard(listGrattaArr[item], day, item)}
            </div>
          );
        })}
      </div>
    );
  };
  const renderStatsCard = (arrGratta, day, vUnita) => {
    if (arrGratta === undefined) return;

    let arrTot = arrGratta.map((t) => t.arrVenduto.map((v) => v.vUnita));
    return arrGratta.map((item, index) => {
      let totB = lodash.sum(arrTot[index]);
      // creo un array con i toatli dei singoli giorni ed il giorno per le statistiche
      let arrChart = [];
      if (day !== "ND") {
        // eslint-disable-next-line array-callback-return
        item.arrChartSingleDay.map((d) =>
          // fix perchè creando un solo valore nello scarico della mazzetta risultava
          // come se fosse stato venduto un solo biglietto dalle stats
          {
            let nBigliettiVendutiFixedChart = d.arrVendite.length;
            d.arrVendite.forEach((c) => {
              if (c.nome.includes("mazzetta")) {
                nBigliettiVendutiFixedChart += [c.vUnita / vUnita] - 1;
              }
            });

            arrChart.unshift([d.data, nBigliettiVendutiFixedChart]);
          }
        );
      }
      arrChart.unshift(["Giorno", "Tot"]);

      // fix perchè creando un solo valore nello scarico della mazzetta risultava
      // come se fosse stato venduto un solo biglietto dalle stats
      let nBigliettiVendutiFixed = item.arrVenduto.length;
      item.arrVenduto.forEach((e) => {
        if (e.nome.includes("mazzetta")) {
          nBigliettiVendutiFixed += [e.vUnita / vUnita] - 1;
        }
      });
      // fine fix
      if (inputAl !== inputDal) {
        arrPropostaPDF.push({
          nome: item.nome,
          vmg: day === "ND" ? "ND" : (nBigliettiVendutiFixed / day).toFixed(1),
          periodo: day,
          imgM: item.imgM,
          nBiglietti: item.nBiglietti,
          nUnita: item.nUnita,
          vUnita: vUnita,
        });
      }

      return (
        <StatsCard
          nome={item.nome}
          codiceM={item.codiceGratta}
          nBigliettiVenduti={nBigliettiVendutiFixed}
          tBigliettiVenduti={totB}
          vmg={day === "ND" ? "ND" : (nBigliettiVendutiFixed / day).toFixed(1)}
          tmg={day === "ND" ? "ND" : (totB / day).toFixed(1)}
          periodo={day}
          arrChart={arrChart}
          imgM={item.imgM}
          nBiglietti={item.nBiglietti}
          nUnita={item.nUnita}
        />
      );
    });
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="statistiche" style={{ marginTop: "15px" }}>
        <div className="statistiche-header">
          <div className="head-scelta">
            <div
              className="local-bootstrap"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                class="input-group input-group-sm me-1 w-30 ipt-report align-items-center row_date"
                style={{ zIndex: "100", flexWrap: "nowrap" }}
              >
                <p>DAL</p>
                <DatePicker
                  locale="it"
                  selected={inputDal}
                  value={inputDal}
                  onChange={(date) => handleDateSelect(date, false)}
                  dateFormat="dd-MM-yyyy"
                  sx={{ position: "absolute", top: "100", left: "0", zIndex: "100" }}
                  maxDate={new Date(inputAl)}
                  className="input_date"
                />
                <CalendarMonthIcon
                  sx={{ color: "#071358", fontSize: "30px" }}
                  style={{ marginLeft: "3px" }}
                />
              </div>

              <div
                class="input-group input-group-sm me-1 w-30 ipt-report align-items-center row_date"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginLeft: "20px",
                }}
              >
                <p>AL</p>
                <DatePicker
                  locale="it"
                  selected={inputAl}
                  value={inputAl}
                  onChange={(date) => handleDateSelect(date, true)}
                  dateFormat="dd-MM-yyyy"
                  sx={{ zIndex: "100" }}
                  maxDate={new Date()}
                  className="input_date"
                />
                <CalendarMonthIcon
                  sx={{ color: "#071358", fontSize: "30px" }}
                  style={{ marginLeft: "3px" }}
                />
              </div>

              <div
                style={{
                  marginLeft: "20px",
                  cursor: "pointer",
                  width: "100px !important",
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={(e) => onClickSearch(e)}
                  sx={{
                    width: "100px !important",
                    bgcolor: "#071358",
                    borderRadius: "10px",
                  }}
                >
                  Cerca
                </Button>
              </div>
              <Link
                style={{ width: "220px !important", marginLeft: "40px" }}
                to={
                  disbaleButton
                    ? "#"
                    : {
                        pathname: `/statistiche/${dateFormater(inputAl)}`,
                        state: {
                          arrStampaVenditeDay: { arrStampaVenditeDay },
                          from: "statistiche",
                        },
                      }
                }
              >
                <Button
                  variant="contained"
                  startIcon={<ManageSearchIcon />}
                  sx={{ width: "220px !important", bgcolor: "#fd8c22" }}
                  style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    cursor: "pointer",
                    width: "auto !important",
                  }}
                  disabled={disbaleButton}
                >
                  Mostra Vendite
                </Button>
              </Link>
              <BootstrapTooltip title="Premendo questo tasto verrà creato un file stampabile delle giacienze di tutti i biglietti ed una Proposta d'ordine ">
                <Link
                  // className="link-pdf"
                  to={
                    !disbaleButton
                      ? "#"
                      : {
                          pathname: `/statistiche/pdf`,
                          state: {
                            arrPropostaPDF: arrPropostaPDF,
                            arrGiacenze: arrPropostaPDF,
                          },
                        }
                  }
                >
                  <Button
                    variant="contained"
                    startIcon={<PictureAsPdfIcon />}
                    sx={{ width: "220px !important", bgcolor: "#fd8c22" }}
                    style={{
                      marginLeft: "20px",
                      marginRight: "20px",
                      cursor: "pointer",
                      width: "auto !important",
                    }}
                    // onClick={rederDatePikers}
                    disabled={!disbaleButton}
                  >
                    Propota D'ordine
                  </Button>
                </Link>
              </BootstrapTooltip>
            </div>
          </div>
        </div>

        <div className="statistiche-giornaliere">
          <div className="single-header">
            <HeaderCardGroup title="STATISTICHE" />
          </div>
          <div className="result-render">
            {loading ? <Spinner /> : renderStats(arrStats)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Statistiche;
