import React from "react";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";
import * as GoIcons from "react-icons/go";
import * as FaIcons from "react-icons/fa";
import * as GrIcons from "react-icons/gr";
export const PlanData = [
  {
    title: "StartUp",
    img: "https://i.ibb.co/bQbnG8k/logo-es-br-40-512-512.png",
    description: (
      <pre>
        Abbonamento <br /> <strong>EasyScratch</strong> <br />
        per 1 anno
      </pre>
    ),
    price: "180 €/anno",
    type: false,
    risparmio: null,
    priceFull: "180 €/anno",
    path: `price_1MQ9kmIBCXaT5dFGHi254RrY`,
    link: "https://buy.stripe.com/bIYfZb68FeeGgSIeUX",
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "1 anno di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
  {
    title: "Plus",
    img: "https://i.ibb.co/bQbnG8k/logo-es-br-40-512-512.png",
    description: (
      <pre>
        Abbonamento <br /> <strong>EasyScratch</strong> <br /> per 2 anni
      </pre>
    ),
    price: "150 €/anno",
    type: false,
    risparmio: ["RISPARMIA IL 17%"],
    priceFull: "180 €/anno",
    path: `price_1LiwdXIBCXaT5dFG0JoTuyVA`,
    link: "https://buy.stripe.com/cN214hfJf7Qi1XO8wF",
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "2 anni di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
  {
    title: "Relax",
    img: "https://i.ibb.co/bQbnG8k/logo-es-br-40-512-512.png",
    description: (
      <pre>
        Abbonamento <br />
        <strong>EasyScratch</strong> <br /> per 3 anni
      </pre>
    ),
    price: "120 €/anno",
    type: false,
    priceFull: "180 €/anno",
    path: `price_1LiwgtIBCXaT5dFGXBrrHx5E`,
    link: "https://buy.stripe.com/bIY3cp54BgmOdGw4gq",
    risparmio: ["RISPARMIA IL 34%"],
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "3 anni di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
  {
    title: "Combo StartUp",
    img: "https://i.ibb.co/WHTrcVF/flow-easy.png",
    description: (
      <pre>
        Abbonamento{" "}
        <strong>
          <br />
          FlowMoney
        </strong>{" "}
        & <strong>EasyScratch</strong>
        <br /> per 1 anno
      </pre>
    ),
    price: "250 €/anno",
    type: true,
    priceFull: "360 €/anno",
    path: `price_1LiJJ5IBCXaT5dFGYuEZGXSO`,
    link: "https://buy.stripe.com/dR614hcx3daC7i83ci",
    risparmio: ["RISPARMIA IL 31%"],
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "1 anno di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
  {
    title: "ComboPlus",
    img: "https://i.ibb.co/WHTrcVF/flow-easy.png",
    description: (
      <pre>
        Abbonamento
        <br /> <strong>FlowMoney</strong> & <strong>EasyScratch</strong>
        <br /> per 2 anni
      </pre>
    ),
    price: "230 €/anno",
    type: true,
    priceFull: "360 €/anno",
    path: `price_1LiO6qIBCXaT5dFGi7lUL27A`,
    link: "https://buy.stripe.com/00g00dcx39Yq31SeV1",
    risparmio: ["RISPARMIA IL 36%"],
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "2 anni di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
  {
    title: "ComboRelax",
    img: "https://i.ibb.co/WHTrcVF/flow-easy.png",
    description: (
      <pre>
        Abbonamento <br />
        <strong>FlowMoney</strong> & <strong>EasyScratch</strong>
        <br /> per 3 anni
      </pre>
    ),
    price: "200 €/anno",
    type: true,
    priceFull: "360 €/anno",
    path: `price_1LiO9bIBCXaT5dFGDxeM862y`,
    link: "https://buy.stripe.com/dR6fZbdB7eeG31SdQY",
    risparmio: ["RISPARMIA IL 45%"],
    specific: [
      { ico: <FaIcons.FaHeadphonesAlt />, text: "Assitenza italiana in remoto" },
      { ico: <GoIcons.GoDesktopDownload />, text: "Formazione gratis in remoto" },
      { ico: <AiIcons.AiOutlineCloudServer />, text: "Cloud Google dedicato" },
      { ico: <HiIcons.HiDatabase />, text: "DB Google dedicato" },
      { ico: <MdIcons.MdOutlineSubscriptions />, text: "3 anni di abbonamento" },
      { ico: <GrIcons.GrSecure />, text: "Sicurezza potenziata" },
      { ico: <MdIcons.MdOutlineSecurity />, text: "Copie di backup on-demand" },
    ],
  },
];
