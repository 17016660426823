import db from "../../Firebase-config";
import { getDoc, doc, setDoc } from "firebase/firestore";

const INSERT_GRATTA_START = "INSERT_GRATTA_START";
const INSERT_GRATTA_SUCCESS = "INSERT_GRATTA_SUCCESS";
const INSERT_GRATTA_FAIL = "INSERT_GRATTA_FAIL";

const MODEL_EXSIST_START = "MODEL_EXSIST_START";
const MODEL_EXSIST_SUCCESS = "MODEL_EXSIST_SUCCESS";
const MODEL_EXSIST_FAIL = "MODEL_EXSIST_FAIL";
const RESET_SERIE_ESISTE = "RESET_SERIE_ESISTE";

const ERROR_RESET_CG = "ERROR_RESET_CG";

export const insertGratta = (codiceBarre, scelta) => {
  return async (dispatch, getState) => {
    dispatch(insertGrattaStart());
    try {
      // Splitto la stringa per ricavarne codice , serie e progressivo
      const codiceM = String(codiceBarre).slice(0, 4);
      const serieM = String(codiceBarre).slice(4, 11);
      const progM = String(codiceBarre).slice(11);
      const uiList = await getState().caricaGrattaReducers.uiList;
      let arrMazzetta = [];
      const arrMazzettaV = ["false"];
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------

      // Dal codiceM controllo nel db che tipo di modello di gratta e vinci è una
      await dispatch(modelExsist(codiceM));
      const exsist = await getState().caricaGrattaReducers.exsist;
      const nUnita = await getState().caricaGrattaReducers.nUnita;
      const imgM = await getState().caricaGrattaReducers.imgM;
      const nome = await getState().caricaGrattaReducers.nome;

      // trovo le referenze del db nello specifico nella mazzetta
      const addRef = doc(db, `${refMag[0]}.magazzino`, nome); // si posiziona sulla chiave unica che adesso è il nome

      const addRefV = doc(addRef, "mazzetta", "venduto");
      const addRefnV = doc(addRef, "mazzetta", "nonVenduto");
      const addRefM = doc(addRefnV, "serie", codiceM + serieM);
      const addRefMnV = doc(addRefV, "serie", codiceM + serieM);
      //------

      if (exsist === false) {
        localStorage.setItem("exsist", "");
      } else {
        // se scelta è true allora deve caricare il biglietto singolo e non la mazzetta intera

        // carica la mazzetta ==>
        // se la serie delle mazzetta non esiste ne crea una nuova e la carica
        const data = await getDoc(addRefM);
        const mExist = data.exists();

        if (mExist === false) {
          // se ha scelto di caricare il biglietto singolo di una mazzetta che non esiste
          if (scelta === true) {
            arrMazzetta.push(codiceM + serieM + progM);
            setDoc(addRefM, { arrMazzetta });
            setDoc(addRefMnV, { arrMazzettaV });
            uiList.unshift({ id: `${codiceM} - ${serieM}`, imgSrc: imgM });
            dispatch(insertGrattaSuccess(codiceM, serieM, uiList));
            localStorage.setItem("exsist", "");
            return;
          }
          // se ha scelto di caricare la mazzeetta intera
          let prog = 0;
          for (let i = 0; i < nUnita; i++) {
            if (i < 10) {
              prog = `00${i}`;
            } else {
              prog = `0${i}`;
            }
            if (i > 99) {
              prog = `${i}`;
            }
            arrMazzetta.push(codiceM + serieM + prog);
          }
          setDoc(addRefM, { arrMazzetta });
          setDoc(addRefMnV, { arrMazzettaV });

          uiList.unshift({ id: `${codiceM} - ${serieM}`, imgSrc: imgM });
          dispatch(insertGrattaSuccess(codiceM, serieM, uiList));
          localStorage.setItem("exsist", "");
        } else {
          // se ha scelto di caricare il biglietto singolo di una mazzetta che già esiste
          if (scelta === true) {
            const dataV = await getDoc(addRefMnV);
            let arrNV =
              data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values;
            let arrV =
              dataV._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values;
            arrNV = arrNV.map((item) => item.stringValue);
            arrV = arrV.map((item) => item.stringValue);
            if (arrV.includes(codiceBarre) || arrNV.includes(codiceBarre)) {
              dispatch(insertGrattaFail("generico", true));
              return;
            }
            arrMazzetta.push(codiceM + serieM + progM);
            arrMazzetta = [...arrNV, ...arrMazzetta];
            arrMazzetta.sort();
            setDoc(addRefM, { arrMazzetta }); // non venduto

            uiList.unshift({ id: `${codiceM} - ${serieM}`, imgSrc: imgM });
            dispatch(insertGrattaSuccess(codiceM, serieM, uiList));
            localStorage.setItem("exsist", "");
            return;
          }
          dispatch(insertGrattaFail("generico", true));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(insertGrattaFail(error, false));
    }
  };
};

export const insertGrattaStart = () => {
  return {
    type: INSERT_GRATTA_START,
  };
};

export const insertGrattaSuccess = (codiceM, serieM, uiList) => {
  return {
    type: INSERT_GRATTA_SUCCESS,
    //action
    codiceM: codiceM,
    serieM: serieM,
    uiList: uiList,
  };
};

export const insertGrattaFail = (error, serieEsiste) => {
  return {
    type: INSERT_GRATTA_FAIL,
    error: error,
    serieEsiste: serieEsiste,
  };
};

export const modelExsist = (codiceM) => {
  return async (dispatch) => {
    dispatch(modelExsistStart());
    try {
      const docRef = doc(db, "modelli", codiceM);
      const data = await getDoc(docRef);

      const exsist = data.exists();

      if (exsist === false) {
        dispatch(modelExsistFail("Codice errato il Modello non esiste"));

        return;
      } else {
        const codiceGratta =
          data._document.data.value.mapValue.fields.codiceGratta.integerValue;
        const nUnita = data._document.data.value.mapValue.fields.nUnita.integerValue;
        const nome = data._document.data.value.mapValue.fields.nome.stringValue;
        const vUnita = data._document.data.value.mapValue.fields.vUnita.integerValue
          ? data._document.data.value.mapValue.fields.vUnita.integerValue
          : data._document.data.value.mapValue.fields.vUnita.doubleValue;
        const imgC = data._document.data.value.mapValue.fields.imgC.stringValue;
        const imgM = data._document.data.value.mapValue.fields.imgM.stringValue;

        dispatch(
          modelExsistSuccess(exsist, codiceGratta, nUnita, nome, vUnita, imgC, imgM)
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(modelExsistFail(error));
    }
  };
};

export const modelExsistStart = () => {
  return {
    type: MODEL_EXSIST_START,
  };
};

export const modelExsistSuccess = (
  exsist,
  codiceGratta,
  nUnita,
  nome,
  vUnita,
  imgC,
  imgM
) => {
  return {
    type: MODEL_EXSIST_SUCCESS,
    exsist: exsist,
    codiceGratta: codiceGratta,
    nUnita: nUnita,
    nome: nome,
    vUnita: vUnita,
    imgC: imgC,
    imgM: imgM,

    //action
  };
};

export const modelExsistFail = (error) => {
  return {
    type: MODEL_EXSIST_FAIL,
    error: error,
  };
};
export const resetSerieEsiste = (serieEsiste) => {
  return {
    type: RESET_SERIE_ESISTE,
    serieEsiste: serieEsiste,
  };
};

export const errorResetCG = () => {
  return {
    type: ERROR_RESET_CG,
  };
};

export {
  INSERT_GRATTA_START,
  INSERT_GRATTA_SUCCESS,
  INSERT_GRATTA_FAIL,
  MODEL_EXSIST_START,
  MODEL_EXSIST_SUCCESS,
  MODEL_EXSIST_FAIL,
  RESET_SERIE_ESISTE,
  ERROR_RESET_CG,
};
