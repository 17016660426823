import CreatePdf from "../components/CreatePdf";
import Navbar from "../components/Navbar";
function pdfViewer(params) {
  const arrPropostaPDF = params.location.state.arrPropostaPDF;
  const arrGiacenze = params.location.state.arrGiacenze;
  const token = localStorage.getItem("token");
  return (
    <>
      {token ? <Navbar isAutenticated={token} /> : null}
      <div className="App">
        <CreatePdf arrPropostaPDF={arrPropostaPDF} arrGiacenze={arrGiacenze} />
      </div>
    </>
  );
}
export default pdfViewer;
