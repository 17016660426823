import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC2tNG3_Y0CZC5z6dXSk7hygkd_W0o6QNU",
  authDomain: "gratta-e-vinci-c3d52.firebaseapp.com",
  databaseURL: "https://gratta-e-vinci-c3d52-default-rtdb.firebaseio.com",
  projectId: "gratta-e-vinci-c3d52",
  storageBucket: "gratta-e-vinci-c3d52.appspot.com",
  messagingSenderId: "884002789297",
  appId: "1:884002789297:web:066f3c72ab3d56875a4f90",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
  console.log("persistance error", err.code);
});

export default db;
