export const introStatsTour = [
  {
    target: `.navbar-toggle`,
    content: `Eccoci nella pagina delle statistiche, 
    qui troverai tanti dati sulla tue vendite che ti permetteranno di monitorare l'andamento dei Gratta&Vinci e 
    quindi evadere i tuoi ordini in maniera più controllata ed intelligente. Dai cominciamo  `,
    disableBeacon: true,
  },
  {
    target: `.stat-general`,
    content: `Qui trovi il totale in € del venduto del giorno corrente e di fianco il rispettivo aggio`,
  },
  {
    target: `.stats-head`,
    content: `Per ogni tipologia di gratta e vinci troverai l'immagine ed il nome `,
  },
  {
    target: `.bv`,
    content: `Numero di biglietti venduti per la specifica categoria nel giorno corrente`,
  },
  {
    target: `.tbv`,
    content: `Totale in € dei biglietti venduti per la specifica categoria nel giorno corrente`,
  },
  {
    target: `.gb`,
    content: `Giacienza residua della specifica categoria`,
  },
  {
    target: `.vtp`,
    content: `Qusta sezione è visualizzabile solo per le statistiche settimanali e mensili perchè è 
    calcolata sui dati del range di data scelto `,
  },
  {
    target: `.chart-stat`,
    content: `Anche i grafici sono disponibili solo per le statistiche settimanali e mensili perchè è 
    calcolata sui dati del range di data scelto `,
  },
  {
    target: `.giorno`,
    content: `Con questo tasto potrai scegliere di visualizzare le statische Giornaliere , 
    che vengono visualizzate di default `,
  },
  {
    target: `.settimana`,
    content: `Con questo quelle settimanali`,
  },
  {
    target: `.mese`,
    content: `Con questo quelle mensili`,
  },
  {
    target: `.settimana`,
    content: `Vediamo più nel dettaglio quelle settimanali. 
    Clicca su questo tasto cosi vederemo le statische settimanali con rispettivi grafici e dati `,
  },
];
