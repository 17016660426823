export const propostaOrdine = (vmg, nBiglietti, nUnita) => {
  // 0 domenica // 1 lunedi // 2 martedi // 3 mercoledi // 4 giovedi // 5 venerdi // 6 sabato
  var data = new Date();
  var hours = data.getHours();
  var gWeek = data.getDay();
  let moltiplicatore = 11;

  if (hours > 10) {
    moltiplicatore = moltiplicatore + 1;
  }

  if (gWeek === 0) {
    moltiplicatore = moltiplicatore + 2;
  }
  if (gWeek === 5) {
    moltiplicatore = moltiplicatore + 4;
  }
  if (gWeek === 6) {
    moltiplicatore = moltiplicatore + 3;
  }
  let po = Math.ceil((vmg * moltiplicatore - nBiglietti) / nUnita);
  if (po < 0) {
    po = 0;
  }

  return po;
  //----------------------------------------------------------------
};
