import * as actionTypes from "../actions/handleHome";

const initialState = {
  loading: false,
  error: false,
  message: "",
  uiVendita: [],
  tot: 0,
  totaleGiornaliero: 0,
  arrDaEliminare: [],
  loadingIncassa: false,
  arrVenditeDay: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SCARICA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.SCARICA_SUCCESS:
      if (action.arrDaEliminare !== "") {
        return {
          ...state,
          //action
          uiVendita: action.uiVendita,
          arrDaEliminare: [...state.arrDaEliminare, action.arrDaEliminare],
          message: "",
          //
          loading: false,
          error: false,
        };
      } else {
        return {
          ...state,
          //action
          uiVendita: action.uiVendita,
          message: "",
          //
          loading: false,
          error: false,
        };
      }
    case actionTypes.SCARICA_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.message,
      };
    case actionTypes.ELIMINA_VENDITA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.ELIMINA_VENDITA_SUCCESS:
      return {
        ...state,
        //action
        uiVendita: action.uiVenditaD,
        arrDaEliminare: action.arrDaEliminare,
        message: "",
        //
        loading: false,
        error: false,
      };
    case actionTypes.ELIMINA_VENDITA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case actionTypes.INCREMENTA_TOT_CARRELLO:
      return {
        ...state,
        tot: state.tot + action.tot,
      };
    case actionTypes.DECREMENTA_TOT_CARRELLO:
      return {
        ...state,
        tot: state.tot - action.tot,
      };

    case actionTypes.FETCH_DAY_TOTAL_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_DAY_TOTAL_SUCCESS:
      return {
        ...state,
        //action
        totaleGiornaliero: action.totaleGiornaliero,
        message: "",

        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_DAY_TOTAL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.INCASSA_START:
      return {
        ...state,
        loadingIncassa: true,
        error: false,
      };
    case actionTypes.INCASSA_SUCCESS:
      return {
        ...state,
        //action
        totaleGiornaliero: action.totaleGiornaliero,
        uiVendita: [],
        arrDaEliminare: action.arrDaEliminare,
        tot: 0,
        message: "",
        //
        loadingIncassa: false,
        error: false,
      };
    case actionTypes.INCASSA_FAIL:
      return {
        ...state,
        loadingIncassa: false,
        error: action.error,
      };
    case actionTypes.STORNA_BIGLIETTO_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.STORNA_BIGLIETTO_SUCCESS:
      return {
        ...state,
        //action
        uiVendita: action.uiVendita,

        //
        loading: false,
        error: false,
      };
    case actionTypes.STORNA_BIGLIETTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case actionTypes.SCARICA_MAZZETTA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.SCARICA_MAZZETTA_SUCCESS:
      return {
        ...state,
        //action
        //
        loading: false,
        error: false,
      };
    case actionTypes.SCARICA_MAZZETTA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case actionTypes.MOSTRA_VENDITE_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.MOSTRA_VENDITE_SUCCESS:
      return {
        ...state,
        //action
        arrVenditeDay: action.arrVenditeDay,
        //
        loading: false,
        error: false,
      };
    case actionTypes.MOSTRA_VENDITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        message: action.message,
      };
    case actionTypes.ERROR_RESET:
      return {
        ...state,
        loading: false,
        error: false,
      };

    default:
      return state;
  }
};

export default reducer;
