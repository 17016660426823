import { doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";
import db from "../../Firebase-config";

const TOUR_SELECTOR_START = "TOUR_SELECTOR_START";
const TOUR_SELECTOR_SUCCESS = "TOUR_SELECTOR_SUCCESS";
const TOUR_SELECTOR_FAIL = "TOUR_SELECTOR_FAIL";

const GET_TOUR_START = "GET_TOUR_START";
const GET_TOUR_SUCCESS = "GET_TOUR_SUCCESS";
const GET_TOUR_FAIL = "GET_TOOUR_FAIL";

const INTRO_TOUR_START = "INTRO_TOUR_START";
const INTRO_CARICA_GRATTA_TOUR_START = "INTRO_CARICA_GRATTA_TOUR_START";
const CARICA_GRATTA_TOUR_START = "CARICA_GRATTA_TOUR_START";
const HOME_TOUR_START = "HOME_TOUR_START";
const HOME_SEARCH_BAR_TOUR_START = "HOME_SEARCH_BAR_TOUR_START";
const INCASSA_TOUR_START = "INCASSA_TOUR_START";
const STORNO_TOUR_START = "STORNO_TOUR_START";
const AFTER_STORNO_TOUR_START = "AFTER_STORNO_TOUR_START";
const INTRO_STATS_TOUR_START = "INTRO_STATS_TOUR_START";
const STATS_TOUR_START = "STATS_TOUR_START";

export const tourSelector = (value) => {
  return async (dispatch) => {
    dispatch(tourSelectorStart());
    try {
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const refTour = doc(db, `${refMag[0]}.magazzino`, "tour");
      await updateDoc(refTour, { start: Boolean(value) });
      await dispatch(getTour());

      dispatch(tourSelectorSuccess());
    } catch (error) {
      dispatch(tourSelectorFail(error));
    }
  };
};

export const tourSelectorStart = () => {
  return {
    type: TOUR_SELECTOR_START,
  };
};

export const tourSelectorSuccess = () => {
  return {
    type: TOUR_SELECTOR_SUCCESS,
  };
};

export const tourSelectorFail = (error) => {
  return {
    type: TOUR_SELECTOR_FAIL,
    error: error,
  };
};
export const getTour = () => {
  return async (dispatch) => {
    dispatch(getTourStart());
    try {
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const refTour = doc(db, `${refMag[0]}.magazzino`, "tour");
      const data = await getDoc(refTour);
      if (data.exists()) {
        const { start } = data.data();
        dispatch(getTourSuccess(start));
      } else {
        await setDoc(refTour, { start: true });
        dispatch(getTourSuccess(true));
      }
    } catch (error) {
      dispatch(getTourFail(error));
    }
  };
};

export const getTourStart = () => {
  return {
    type: GET_TOUR_START,
  };
};

export const getTourSuccess = (start) => {
  return {
    type: GET_TOUR_SUCCESS,
    start,
  };
};

export const getTourFail = (error) => {
  return {
    type: GET_TOUR_FAIL,
    error: error,
  };
};

export const introTourStart = () => {
  return {
    type: INTRO_TOUR_START,
  };
};

export const introCaricaGrattaTourStart = () => {
  return {
    type: INTRO_CARICA_GRATTA_TOUR_START,
  };
};

export const caricaGrattaTourStart = () => {
  return {
    type: CARICA_GRATTA_TOUR_START,
  };
};
export const homeTourStart = () => {
  return {
    type: HOME_TOUR_START,
  };
};
export const homeSearchBarTourStart = () => {
  return {
    type: HOME_SEARCH_BAR_TOUR_START,
  };
};
export const incassaStart = () => {
  return {
    type: INCASSA_TOUR_START,
  };
};
export const stornoTourStart = () => {
  return {
    type: STORNO_TOUR_START,
  };
};
export const afterstornoTourStart = () => {
  return {
    type: AFTER_STORNO_TOUR_START,
  };
};
export const introStatsTourStart = () => {
  return {
    type: INTRO_STATS_TOUR_START,
  };
};
export const statsTourStart = () => {
  return {
    type: STATS_TOUR_START,
  };
};

export {
  TOUR_SELECTOR_START,
  TOUR_SELECTOR_SUCCESS,
  TOUR_SELECTOR_FAIL,
  GET_TOUR_START,
  GET_TOUR_SUCCESS,
  GET_TOUR_FAIL,
  INTRO_CARICA_GRATTA_TOUR_START,
  INTRO_TOUR_START,
  CARICA_GRATTA_TOUR_START,
  HOME_TOUR_START,
  HOME_SEARCH_BAR_TOUR_START,
  INCASSA_TOUR_START,
  STORNO_TOUR_START,
  AFTER_STORNO_TOUR_START,
  INTRO_STATS_TOUR_START,
  STATS_TOUR_START,
};
