export const stornoTour = [
  {
    target: `.vendita-result`,
    content: `Bravissimo stai andando alla grande`,
    disableBeacon: true,
  },
  {
    target: `.tab-storno`,
    content: `Come vedi hai creato lo storno nel carrello 
    ed il totale del carrello è negativo ora premi incassa ed
     il gioco è fatto.`,
  },
  {
    target: `.tab-storno`,
    content: `Attenzione puoi stornare solo biglietti singoli non mazzette intere questo è l'unico vincolo`,
  },
];
