import { useState } from "react";
import "../styles/Auth.css";
import "../styles//Price.scss";
import { useSelector, useDispatch } from "react-redux";
import { auth, resetPassword } from "../store/actions/handelAuth";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

const Auth = () => {
  const [email, setmail] = useState("");
  const [password, setpassword] = useState("");

  const userToken = useSelector((state) => state.authReducer.token);
  const error = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);

  const dispatch = useDispatch();

  const handleEmail = (e) => {
    setmail(e.target.value);
  };
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(auth(email, password, false));
    setpassword("");
    setpassword("");
  };

  let shouldRedirect = null;
  if (userToken) {
    shouldRedirect = <Redirect to="/" />;
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const { value: text } = await Swal.fire({
      title: "Hai dimenticato la Password ?",
      input: "text",
      inputLabel:
        "inserisci qui la tua mail ed invieremo un link con il quale potrai resettarla",
      inputPlaceholder: "inserisci qui la mail",
      showCancelButton: true,
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    });
    if (text) {
      dispatch(resetPassword(text));
    }
  };

  return (
    <div className="local-bootstrap">
      {shouldRedirect}
      <div className="container h-100">
        <div className="row justify-content-sm-center h-100">
          <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
            <div className="text-center my-5">
              <img
                src="https://i.ibb.co/bQbnG8k/logo-es-br-40-512-512.png"
                alt="logo"
                width="100"
              />
            </div>
            <div className="card shadow-lg">
              <div className="card-body p-5">
                <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
                <form
                  method="POST"
                  className="needs-validation"
                  noValidate=""
                  autoComplete="off"
                >
                  <div className="mb-3">
                    <label className="mb-2 text-muted" htmlFor="email">
                      E-Mail
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={handleEmail}
                      required
                      autoFocus
                    />
                    <div className="invalid-feedback">Email is invalid</div>
                  </div>

                  <div className="mb-3">
                    <div className="mb-2 w-100">
                      <label className="text-muted" htmlFor="password">
                        Password
                      </label>
                      <a
                        href="forgot.html"
                        className="float-end"
                        onClick={handleResetPassword}
                      >
                        Password dimenticata ?
                      </a>
                    </div>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={handlePassword}
                      required
                    />
                    <div className="invalid-feedback">Password is required</div>
                  </div>

                  <div className="d-flex align-items-center btn-log-fm">
                    <LoadingButton
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                      onClick={handleSubmit}
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "40px",
                        borderRadius: "3px",
                      }}
                      sx={{ bgcolor: "#071358", color: "white" }}
                    >
                      Login
                    </LoadingButton>

                    {/* <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      style={{ maxHeight: "45px" }}
                    >
                      {loading ? renderLoadingSpinner() : null}
                      <span style={{ margin: 0, marginBottom: "10px" }}>Login</span>
                    </button> */}
                  </div>
                  {/* <div className="d-flex align-items-center"> */}
                  {error ? (
                    <div className="alert alert-danger mt-3" role="alert">
                      {error}
                    </div>
                  ) : null}
                </form>
              </div>
              <div className="card-footer py-3 border-0">
                <div className="text-center">
                  Non hai un account ?{" "}
                  <Link
                    to={{
                      pathname: `/price`,
                    }}
                  >
                    <p href="register.html" className="text-dark">
                      Registrati
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="text-center mt-5 text-muted">
              Copyright &copy; 2022 &mdash; Lo.Sa. Technology Solutions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Auth;
