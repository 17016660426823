import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import CaricaGratta from "./pages/CaricaGratta";
import Statistiche from "./pages/Statistiche";
import Auth from "./pages/Auth";
import Magazzino from "./pages/Magazzino";
import Logout from "./pages/Logout";
import SingleMag from "./pages/SingleMag";
import Registrazione from "./pages/Registrazione";
import Admin from "./pages/Admin";

import StampaVenditeGiornaliere from "./pages/StampaVenditeGiornaliere";
import PDFViewer from "./pages/Pdfview";
import ContactForm from "./pages/ContactForm";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { authCheck } from "./store/actions/handelAuth";
import { PlanData } from "../src/components/priceContainer/PlanData";
import Price from "./pages/Price";
import Help from "./pages/Help";
import Tour from "../src/components/Tutor";
import { introTour } from "../src/components/tours/IntroTour";
import { searchBarTour } from "../src/components/tours/SearcBarTour";
import { stornoTour } from "../src/components/tours/StornoTour";
import { incassaTour } from "../src/components/tours/IncassaTour";
import { getTour } from "./store/actions/handleTour";
import { introCaricaGrattaTour } from "./components/tours/IntroCaricaGrattaTour";
import { caricaGrattaTour } from "./components/tours/CaricaGrattaTour";
import { homeTour } from "./components/tours/HomeTour";
import { afterStornoTour } from "./components/tours/AfterStornoTour";
import { introStatsTour } from "./components/tours/introStatsTour";
import { statsTour } from "./components/tours/statsTour1";

function App() {
  const dispatch = useDispatch();

  const {
    start,
    startIntroTour,
    startIntroCaricaGratta,
    startCaricaGrattaTour,
    startHomeTour,
    startSearchBarTour,
    startIncassaTour,
    startStornoTour,
    startAfterStornoTour,
    startIntroStatsTour,
    startStatsTour,
  } = useSelector((state) => state.tourReducer);

  useEffect(() => {
    // checkValidSubscription();
    dispatch(authCheck());
    dispatch(getTour());
  }, [dispatch]);

  return (
    <>
      {start ? (
        <>
          <Tour run={startIntroTour} tour={introTour} />
          <Tour run={startIntroCaricaGratta} tour={introCaricaGrattaTour} />
          <Tour run={startCaricaGrattaTour} tour={caricaGrattaTour} />
          <Tour run={startHomeTour} tour={homeTour} />
          <Tour run={startSearchBarTour} tour={searchBarTour} />
          <Tour run={startStornoTour ? null : startIncassaTour} tour={incassaTour} />
          <Tour run={startAfterStornoTour ? null : startStornoTour} tour={stornoTour} />
          <Tour run={startAfterStornoTour} tour={afterStornoTour} />
          <Tour run={startIntroStatsTour} tour={introStatsTour} />
          <Tour run={startStatsTour} tour={statsTour} />
        </>
      ) : null}

      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/carica-gratta-e-vinci" component={CaricaGratta} />

        <Route path="/statistiche/pdf" component={PDFViewer} />
        <Route path="/statistiche/:id" component={StampaVenditeGiornaliere} />
        <Route path="/statistiche" component={Statistiche} />
        <Route path="/auth" component={Auth} />
        <Route path="/logout" component={Logout} />
        <Route path="/magazzino/:id" component={SingleMag} />
        <Route path="/magazzino" component={Magazzino} />
        <Route path="/registrazione" component={Registrazione} />
        <Route path="/help" component={Help} />
        <Route path="/contactform" component={ContactForm} />
        <Route path="/admin" component={Admin} />
        <Route path="/price" component={Price} />

        {/* <Route
          path="/payment"
          component={() => {
            window.location.href = "https://buy.stripe.com/9AQdR37cJfiKfOE5km";
            return null;
          }}
        /> */}
        {PlanData.map((item) => {
          return (
            <Route
              path={item.path}
              component={() => {
                window.location.href = item.link;

                return null;
              }}
            />
          );
        })}
      </Switch>
      {/* </Online> */}
    </>
  );
}

export default App;
