import * as actionTypes from "../actions/handleCaricaGratta";

const initialState = {
  loading: false,
  error: false,
  exsist: true,
  nModel: null,
  nSerie: null,
  uiList: [],
  serieEsiste: false,
  codiceGratta: null,
  vUnita: null,
  nome: null,
  nUnita: null,
  imgC: null,
  imgM: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INSERT_GRATTA_START:
      return {
        ...state,
        loading: true,
        error: false,
        serieEsiste: false,
      };
    case actionTypes.INSERT_GRATTA_SUCCESS:
      return {
        ...state,
        //action
        nModel: action.codiceM,
        nSerie: action.serieM,
        uiList: action.uiList,
        serieEsiste: action.serieEsiste,
        //
        loading: false,
        error: false,
      };
    case actionTypes.INSERT_GRATTA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        serieEsiste: action.serieEsiste,
      };

    case actionTypes.MODEL_EXSIST_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.MODEL_EXSIST_SUCCESS:
      return {
        ...state,
        //action
        exsist: action.exsist,
        codiceGratta: action.codiceGratta,
        nUnita: action.nUnita,
        vUnita: action.vUnita,
        nome: action.nome,
        imgC: action.imgC,
        imgM: action.imgM,
        //
        loading: true,
        error: false,
      };
    case actionTypes.MODEL_EXSIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        exsist: false,
      };
    case actionTypes.RESET_SERIE_ESISTE:
      return {
        ...state,
        loading: false,
        serieEsiste: action.serieEsiste,
      };
    case actionTypes.ERROR_RESET_CG:
      return {
        ...state,
        loading: false,
        error: false,
      };

    default:
      return state;
  }
};

export default reducer;
