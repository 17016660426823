import {
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
} from "@firebase/firestore";

import db from "../../Firebase-config";
import { insertGratta, modelExsist } from "./handleCaricaGratta";
import { deleteMazzetta } from "./handleSingleMag";
import { DataTranform } from "../../functions/DateTranform";

const SCARICA_START = "SCARICA_START";
const SCARICA_SUCCESS = "SCARICA_SUCCESS";
const SCARICA_FAIL = "SCARICA_FAIL";

const ELIMINA_VENDITA_START = "ELIMINA_VENDITA_START";
const ELIMINA_VENDITA_SUCCESS = "ELIMINA_VENDITA_SUCCESS";
const ELIMINA_VENDITA_FAIL = "ELIMINA_VENDITA_FAIL";

const INCREMENTA_TOT_CARRELLO = "INCREMENTA_TOT_CARRELLO";
const DECREMENTA_TOT_CARRELLO = "DECREMENTA_TOT_CARRELLO";

const FETCH_DAY_TOTAL_START = "FETCH_HOME_TOTAL_START";
const FETCH_DAY_TOTAL_SUCCESS = "FETCH_HOME_TOTAL_SUCCES";
const FETCH_DAY_TOTAL_FAIL = "FETCH_HOME_TOTAL_FAIL";

const INCASSA_START = "INCASSA_START";
const INCASSA_SUCCESS = "INCASSA_SUCCES";
const INCASSA_FAIL = "INCASSA_FAIL";

const STORNA_BIGLIETTO_START = "STORNA_BIGLIETTO_START";
const STORNA_BIGLIETTO_SUCCESS = "STORNA_BIGLIETTO_SUCCES";
const STORNA_BIGLIETTO_FAIL = "STORNA_BIGLIETTO_FAIL";

const SCARICA_MAZZETTA_START = "SCARICA_MAZZETTA_START";
const SCARICA_MAZZETTA_SUCCESS = "SCARICA_MAZZETTA_SUCCES";
const SCARICA_MAZZETTA_FAIL = "SCARICA_MAZZETTA_FAIL";

const MOSTRA_VENDITE_START = "MOSTRA_VENDITE_START";
const MOSTRA_VENDITE_SUCCESS = "MOSTRA_VENDITE_SUCCES";
const MOSTRA_VENDITE_FAIL = "MOSTRA_VENDITE_FAIL";

const ERROR_RESET = "ERROR_RESET";

export const scarica = (codiceBarre) => {
  return async (dispatch, getState) => {
    document.getElementById("input-text").disabled = true;
    dispatch(scaricaStart());
    try {
      // Splitto la stringa per ricavarne codice , serie e progressivo
      const codiceM = String(codiceBarre).slice(0, 4);
      const serieM = String(codiceBarre).slice(4, 11);
      const progM = String(codiceBarre).slice(11);
      let uiVendita = await getState().homeReducers.uiVendita;
      let arrDaEliminare = "";
      // riferimento univo del CaricaGratta Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------
      await dispatch(modelExsist(codiceM));
      const exsist = getState().caricaGrattaReducers.exsist;

      if (exsist === false) {
        document.getElementById("input-text").disabled = false;
        await dispatch(scaricaFail(true, "codice non caricato in CaricaGratta"));
        document.getElementById("input-text").focus();

        return;
      } else {
        // // riferimenti dello specifoco gratta e vinci
        const vUnita = getState().caricaGrattaReducers.vUnita;
        const nome = getState().caricaGrattaReducers.nome;
        const imgM = getState().caricaGrattaReducers.imgM;
        //------

        // controllo se il codiceGratta insererito esiste nel database
        const addRef = doc(db, `${refMag[0]}.magazzino`, nome); // riferimento del db nello specifici dellla mazzetta

        const addRefV = doc(addRef, "mazzetta", "venduto"); // rif biglietti  venduti
        const addRefMV = doc(addRefV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

        const addRefnV = doc(addRef, "mazzetta", "nonVenduto"); // rif biglietti non ancora venduti
        const addRefM = doc(addRefnV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

        // controlliamo se la mazzetta esiste
        let data = await getDoc(addRefM);
        const mExist = data.exists();
        if (mExist === false) {
          document.getElementById("input-text").disabled = false;
          await dispatch(scaricaFail(true, "codice non presente in magazzino"));
          document.getElementById("input-text").focus();

          return;
        } else {
          const arrMazz =
            data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values;
          let arrMazzetta = arrMazz.map((item) => item.stringValue);
          const inMag = arrMazzetta.includes(codiceBarre);
          if (inMag === false) {
            document.getElementById("input-text").disabled = false;
            await dispatch(scaricaFail(true, "il Gratta&Vinci è gia stato venduto"));
            document.getElementById("input-text").focus();

            return;
          }
          // console.log("prodotto scaricato");
          data = await getDoc(addRefMV);
          const arrMazzV =
            data._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values;
          let arrMazzettaV = arrMazzV.map((item) => item.stringValue);
          // creo l'arrray del non Venduto senza il codice a barre venduto per poi caricarlo nel magazzino
          arrMazzetta = arrMazzetta.filter((item) => item !== codiceBarre);

          // creo l'arrray del Venduto con il codice a barre venduto per poi caricarlo nel magazzino
          if (arrMazzettaV.includes("false")) {
            arrMazzettaV = arrMazzettaV.filter((item) => item !== "false");
          }
          if (arrMazzetta.length < 1) {
            arrMazzetta.push("venduta");
            arrDaEliminare = `${serieM}+${codiceM}+${nome}`;
          }

          arrMazzettaV.push(codiceBarre);

          setDoc(addRefM, { arrMazzetta });
          setDoc(addRefMV, { arrMazzettaV });
        }

        //----
        // cerco la giacenza di quel biglietto del
        let dataG = await getDocs(collection(addRefnV, `serie`));

        let arrGiac = dataG.docs.map((x) =>
          x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
            (item) => item.stringValue
          )
        );
        let newArray = [];
        arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
        newArray = newArray.filter((item) => item !== "venduta");
        let nBiglietti = newArray.length;
        uiVendita.forEach((element) => {
          if (
            element.nome === nome ||
            element.nome.slice(7) === nome ||
            element.nome.slice(9) === nome
          ) {
            element.nBiglietti = nBiglietti;
          }
        });

        // aggiornare la giacenza dei biglietti con lo stesso nome

        // let nBiglietti = dataG.docs.map(
        //   (x) =>
        //     x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.length
        // );

        // nBiglietti = lodash.sum(nBiglietti);

        // //-------
        // if (newArray.includes("venduta") && newArray.length === 1) {
        //   nBiglietti = 0;
        // }
        // if (newArray.includes("venduta") && newArray.length !== 1) {
        //   nBiglietti = nBiglietti - 1;
        // }

        // aggiono la uiVendita
        uiVendita.unshift({
          nome: nome,
          serieM: serieM,
          progM: progM,
          vUnita: vUnita,
          imgM: imgM,
          key: codiceBarre,
          nBiglietti: nBiglietti,
        });
        //----

        document.getElementById("input-text").disabled = false;
        dispatch(incrementaTotCarrello(Number(vUnita)));
        await dispatch(scaricaSuccess(uiVendita, arrDaEliminare));
        document.getElementById("input-text").focus();
      }

      //   console.log(dataModel);
      //salvo i volori specifici del gratta&vinci scaricato
    } catch (error) {
      document.getElementById("input-text").disabled = false;
      await dispatch(scaricaFail(error, "errore generico"));
      document.getElementById("input-text").focus();
      console.log(error);
    }
  };
};

export const scaricaStart = () => {
  return {
    type: SCARICA_START,
  };
};

export const scaricaSuccess = (uiVendita, arrDaEliminare) => {
  return {
    type: SCARICA_SUCCESS,
    uiVendita: uiVendita,
    arrDaEliminare: arrDaEliminare,
    //action
  };
};

export const scaricaFail = (error, message) => {
  return {
    type: SCARICA_FAIL,
    error: error,
    message: message,
  };
};
export const scaricaMazzetta = (codiceBarre) => {
  return async (dispatch, getState) => {
    dispatch(scaricaMazzettaStart());

    try {
      // Splitto la stringa per ricavarne codice , serie e progressivo
      const codiceM = String(codiceBarre).slice(0, 4);
      const serieM = String(codiceBarre).slice(4, 11);
      let uiVendita = await getState().homeReducers.uiVendita;
      const arrDaEliminare = "";

      //------
      await dispatch(modelExsist(codiceM));
      const exsist = getState().caricaGrattaReducers.exsist;
      if (exsist === false) {
        dispatch(scaricaMazzettaFail(true, "codice non caricato in CaricaGratta"));
      } else {
        // // riferimenti dello specifoco gratta e vinci
        // const codiceGratta = getState().caricaGrattaReducers.codiceGratta;
        const nUnita = getState().caricaGrattaReducers.nUnita;
        const vUnita = getState().caricaGrattaReducers.vUnita;
        const nome = getState().caricaGrattaReducers.nome;
        const imgM = getState().caricaGrattaReducers.imgM;

        //verifco che la mazzetta sia completamente non venduta
        // se è presente 'false' nell'array 'venduto' equivale ad una mazzetta con nessun biglietto venduto
        const email = localStorage.getItem("email");
        const refMag = email.split("@");
        const addRef = doc(db, `${refMag[0]}.magazzino`, nome); // riferimento del db nello specifici dellla mazzetta
        const addRefV = doc(addRef, "mazzetta", "venduto"); // rif biglietti  venduti
        const addRefMV = doc(addRefV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti
        const addRefnV = doc(addRef, "mazzetta", "nonVenduto"); // rif biglietti non ancora venduti
        // const addRefM = doc(addRefnV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

        // let data = await getDoc(addRefM);
        // const arrMazz =
        //   data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values;
        // let arrMazzetta = arrMazz.map((item) => item.stringValue);

        let data = await getDoc(addRefMV);
        const arrMazzV =
          data._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values;
        let arrMazzettaV = arrMazzV.map((item) => item.stringValue);

        if (arrMazzettaV.includes("false")) {
          //console.log("la mazzetta si può scaricare");
          // come prima cosa elimino la mazzetta dal db
          await dispatch(deleteMazzetta(codiceM + serieM, nome));
          // vado a creare la vendita nel carrello
          // cerco la giacenza di quel biglietto del
          let dataG = await getDocs(collection(addRefnV, `serie`));

          let arrGiac = dataG.docs.map((x) =>
            x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
              (item) => item.stringValue
            )
          );
          let newArray = [];
          arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
          newArray = newArray.filter((item) => item !== "venduta");
          let nBiglietti = newArray.length;
          uiVendita.forEach((element) => {
            if (
              element.nome === nome ||
              element.nome.slice(7) === nome ||
              element.nome.slice(9) === nome
            ) {
              element.nBiglietti = nBiglietti;
            }
          });

          // aggiono la uiVendita
          uiVendita.unshift({
            nome: "mazzetta " + nome,
            serieM: serieM,
            progM: "000",
            vUnita: vUnita * nUnita,
            imgM: imgM,
            key: codiceBarre,
            nBiglietti: nBiglietti,
          });
          //----

          document.getElementById("input-text").disabled = false;
          dispatch(incrementaTotCarrello(Number(vUnita * nUnita)));
          await dispatch(scaricaSuccess(uiVendita, arrDaEliminare));
          document.getElementById("input-text").focus();
        } else {
          dispatch(
            scaricaMazzettaFail(
              true,
              "la mazzetta non è intera quindi non è possibile venderla come tale"
            )
          );
        }

        // let progM = 0;
        // for (let i = 0; i < nUnita; i++) {
        //   if (i < 10) {
        //     progM = `00${i}`;
        //   } else {
        //     progM = `0${i}`;
        //   }
        //   if (i > 99) {
        //     progM = `${i}`;
        //   }
        //   codiceBarre = codiceM + serieM + progM;

        //   await dispatch(scarica(codiceBarre));
        // }

        //----
      }

      //   console.log(dataModel);
      //salvo i volori specifici del gratta&vinci scaricato
    } catch (error) {
      dispatch(scaricaMazzettaFail(error, "errore generico"));
      console.log(error);
    }
  };
};

export const scaricaMazzettaStart = () => {
  return {
    type: SCARICA_MAZZETTA_START,
  };
};

export const scaricaMazzettaSuccess = () => {
  return {
    type: SCARICA_MAZZETTA_SUCCESS,
    //action
  };
};

export const scaricaMazzettaFail = (error, message) => {
  return {
    type: SCARICA_MAZZETTA_FAIL,
    error: error,
    message: message,
  };
};
export const mostraVendite = (date) => {
  return async (dispatch) => {
    dispatch(mostraVenditeStart());

    let arrVenditeDay = [];
    let totaleGiornaliero = 0;
    // riferimento univo del magazzino Utente
    const email = localStorage.getItem("email");
    const refMag = email.split("@");
    const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici dellla mazzetta
    const addReDay = doc(addRefCalendario, "giorni", date); // rif biglietti non ancora venduti
    // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
    let data = await getDoc(addReDay);
    const dExist = data.exists();
    let vVuota = 0;
    if (dExist === false) {
      arrVenditeDay.push("false");
      vVuota = true;
      await setDoc(addReDay, { arrVenditeDay });
      await updateDoc(addReDay, { totaleGiornaliero });
    } else {
      vVuota = false;
      totaleGiornaliero = data._document.data.value.mapValue.fields.totaleGiornaliero
        .integerValue
        ? data._document.data.value.mapValue.fields.totaleGiornaliero.integerValue
        : data._document.data.value.mapValue.fields.totaleGiornaliero.doubleValue;
      arrVenditeDay =
        data._document.data.value.mapValue.fields.arrVenditeDay.arrayValue.values;
    }

    arrVenditeDay = arrVenditeDay.map((index) => {
      let arrStr = vVuota
        ? 0
        : index.stringValue.includes("false")
        ? 0
        : index.stringValue.split("/");

      return {
        nome: arrStr === 0 ? "0" : arrStr[1],
        key: arrStr === 0 ? "0" : arrStr[0],
        codiceM: arrStr === 0 ? "0" : arrStr[0].slice(0, 4),
        vUnita: arrStr === 0 ? 0 : Number(arrStr[2]),
      };
    });

    dispatch(mostraVenditeSuccess(arrVenditeDay));

    try {
    } catch (error) {
      dispatch(mostraVenditeFail(error, "errore generico"));
      console.log(error);
    }
  };
};

export const mostraVenditeStart = () => {
  return {
    type: MOSTRA_VENDITE_START,
  };
};

export const mostraVenditeSuccess = (arrVenditeDay) => {
  return {
    type: MOSTRA_VENDITE_SUCCESS,
    arrVenditeDay: arrVenditeDay,
    //action
  };
};

export const mostraVenditeFail = (error, message) => {
  return {
    type: MOSTRA_VENDITE_FAIL,
    error: error,
    message: message,
  };
};

export const eliminaVendita = (key, vUnita, nome) => {
  let nomeIniziale = nome;
  if (nome.includes("storno ")) {
    nome = nome.slice(7);
  }
  if (nome.includes("mazzetta ")) {
    nome = nome.slice(9);
  }

  return async (dispatch, getState) => {
    dispatch(eliminaVenditaStart());

    try {
      let uiVenditaD = await getState().homeReducers.uiVendita;
      uiVenditaD = uiVenditaD.filter((item) => item.key !== key);
      let arrDaEliminare = await getState().homeReducers.arrDaEliminare;
      // const nUnita = getState().caricaGrattaReducers.nUnita;

      //aggiorno i riferimenti nel db
      // Splitto la stringa per ricavarne codice , serie e progressivo
      const codiceM = String(key).slice(0, 4);
      const serieM = String(key).slice(4, 11);

      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------

      const addRef = doc(db, `${refMag[0]}.magazzino`, nome); // riferimento del db nello specifici dellla mazzetta

      const addRefV = doc(addRef, "mazzetta", "venduto"); // rif biglietti  venduti
      const addRefMV = doc(addRefV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta  venduti

      const addRefnV = doc(addRef, "mazzetta", "nonVenduto"); // rif biglietti non ancora venduti
      const addRefM = doc(addRefnV, "serie", codiceM + serieM); // rif specifica colleiozione dove si trova il campo gratta non venduti

      if (nomeIniziale.includes("mazzetta")) {
        await dispatch(insertGratta(key, false));
        // cerco la giacenza di quel biglietto del
        let dataG = await getDocs(collection(addRefnV, `serie`));

        let arrGiac = dataG.docs.map((x) =>
          x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
            (item) => item.stringValue
          )
        );
        let newArray = [];
        arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
        newArray = newArray.filter((item) => item !== "venduta");
        let nBiglietti = newArray.length;
        uiVenditaD.forEach((element) => {
          if (
            element.nome === nome ||
            element.nome.slice(7) === nome ||
            element.nome.slice(9) === nome
          ) {
            element.nBiglietti = nBiglietti;
          }
        });

        dispatch(decrementaTotCarrello(Number(vUnita)));
        dispatch(eliminaVenditaSuccess(uiVenditaD, arrDaEliminare));

        return;
      }

      let data = await getDoc(addRefM);
      const arrMazz =
        data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values;
      let arrMazzetta = arrMazz.map((item) => item.stringValue);

      data = await getDoc(addRefMV);
      const arrMazzV =
        data._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values;
      let arrMazzettaV = arrMazzV.map((item) => item.stringValue);

      if (nomeIniziale.includes("storno") === true) {
        // vuol dire che era l'unico biglietto vednuto della mazzzetta quindi dobbiamo rimettere false nell'array
        if (arrMazzettaV.includes("false")) {
          // devo elimare lo storno e quindi rimettere il Biglietto nella mazzatta  venduta
          arrMazzetta = arrMazzetta.filter((item) => item !== key);
          arrMazzettaV.push(key);
          arrMazzettaV = arrMazzettaV.filter((item) => item !== "false");

          setDoc(addRefM, { arrMazzetta });
          setDoc(addRefMV, { arrMazzettaV });

          //---
          // cerco la giacenza di quel biglietto del
          let dataG = await getDocs(collection(addRefnV, `serie`));

          let arrGiac = dataG.docs.map((x) =>
            x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
              (item) => item.stringValue
            )
          );
          let newArray = [];
          arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
          newArray = newArray.filter((item) => item !== "venduta");
          let nBiglietti = newArray.length;
          uiVenditaD.forEach((element) => {
            if (element.nome === nome || element.nome.slice(7) === nome) {
              element.nBiglietti = nBiglietti;
            }
          });

          dispatch(decrementaTotCarrello(Number(vUnita)));
          dispatch(eliminaVenditaSuccess(uiVenditaD, arrDaEliminare));
          return;
        } else {
          // devo elimare lo storno e quindi rimettere il Biglietto nella mazzatta non venduta
          arrMazzetta = arrMazzetta.filter((item) => item !== key);
          arrMazzettaV.push(key);
          arrMazzetta = arrMazzetta.filter((item) => item !== "false");
        }
      } else {
        arrMazzettaV = arrMazzettaV.filter((item) => item !== key);
        arrMazzetta.push(key);
      }

      if (arrMazzettaV.length === 0) {
        arrMazzettaV.push("false");
      }

      if (arrMazzetta.includes("venduta")) {
        arrMazzetta = arrMazzetta.filter((item) => item !== "venduta");
        arrDaEliminare = arrDaEliminare.filter(
          (item) => item !== `${serieM}+${codiceM}+${nome}`
        );
      }

      setDoc(addRefM, { arrMazzetta });
      setDoc(addRefMV, { arrMazzettaV });

      // cerco la giacenza di quel biglietto del
      let dataG = await getDocs(collection(addRefnV, `serie`));

      let arrGiac = dataG.docs.map((x) =>
        x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
          (item) => item.stringValue
        )
      );
      let newArray = [];
      arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
      newArray = newArray.filter((item) => item !== "venduta");
      let nBiglietti = newArray.length;
      uiVenditaD.forEach((element) => {
        if (
          element.nome === nome ||
          element.nome.slice(7) === nome ||
          element.nome.slice(9) === nome
        ) {
          element.nBiglietti = nBiglietti;
        }
      });

      //---
      dispatch(decrementaTotCarrello(Number(vUnita)));
      dispatch(eliminaVenditaSuccess(uiVenditaD, arrDaEliminare));
    } catch (error) {
      console.log(error);
      dispatch(eliminaVenditaFail(error));
    }
  };
};

export const eliminaVenditaStart = () => {
  return {
    type: ELIMINA_VENDITA_START,
  };
};

export const eliminaVenditaSuccess = (uiVenditaD, arrDaEliminare) => {
  return {
    type: ELIMINA_VENDITA_SUCCESS,
    uiVenditaD: uiVenditaD,
    arrDaEliminare: arrDaEliminare,
    //action
  };
};

export const eliminaVenditaFail = (error) => {
  return {
    type: ELIMINA_VENDITA_FAIL,
    error: error,
  };
};
export const incrementaTotCarrello = (tot) => {
  return {
    type: INCREMENTA_TOT_CARRELLO,
    tot: tot,
    //action
  };
};

export const decrementaTotCarrello = (tot) => {
  return {
    type: DECREMENTA_TOT_CARRELLO,
    tot: tot,
  };
};

export const fetchDayTotal = (day) => {
  console.log(day);
  return async (dispatch) => {
    dispatch(fetchDayTotalStart());
    try {
      let arrVenditeDay = [];
      let totaleGiornaliero = 0;
      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------

      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici dellla mazzetta
      const addReDay = doc(addRefCalendario, "giorni", day); // rif biglietti non ancora venduti
      // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno

      for (let i = 0; i < 3; i++) {
        // cerco ed elimino la la data più vecchia di 90 gg per evitare che il db si appesantisca troppo
        let dateArr = day.split("-");
        let dataTemp = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);

        let days = 86400000; //number of milliseconds in a day
        let dataT = DataTranform(new Date(dataTemp - (90 + i) * days)) 
        const addReDayDelete = doc(
          addRefCalendario,
          "giorni",
          dataT
        );
        let DayDelete = await getDoc(addReDayDelete);;
        const dDExist = DayDelete.exists();
        if (dDExist === true) {
          deleteDoc(addReDayDelete);
        }
      }

      let data = await getDoc(addReDay);
      const dExist = data.exists();
      if (dExist === false) {
        arrVenditeDay.push("false");
        setDoc(addReDay, { arrVenditeDay });
        updateDoc(addReDay, { totaleGiornaliero });
      } else {
        totaleGiornaliero = data._document.data.value.mapValue.fields.totaleGiornaliero
          .integerValue
          ? data._document.data.value.mapValue.fields.totaleGiornaliero.integerValue
          : data._document.data.value.mapValue.fields.totaleGiornaliero.doubleValue;
      }
      dispatch(fetchDayTotalSuccess(Number(totaleGiornaliero)));
    } catch (error) {
      dispatch(fetchDayTotalFail(error));
      console.log(error);
    }
  };
};
export const fetchDayTotalStart = () => {
  return {
    type: FETCH_DAY_TOTAL_START,
  };
};

export const fetchDayTotalSuccess = (totaleGiornaliero) => {
  return {
    type: FETCH_DAY_TOTAL_SUCCESS,
    //action
    totaleGiornaliero: totaleGiornaliero,
  };
};

export const fetchDayTotalFail = (error) => {
  return {
    type: FETCH_DAY_TOTAL_FAIL,
    error: error,
  };
};

export const incassa = (arrVenditeCarrello, daySelected) => {
  console.log(daySelected);
  return async (dispatch, getState) => {
    dispatch(incassaStart());
    try {
      document.getElementById("btn-incassa").disabled = true;
      let arrDaEliminare = getState().homeReducers.arrDaEliminare;
      //   console.log(arrVendita);
      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      //------
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici dellla mazzetta
      const addReDay = doc(addRefCalendario, "giorni", daySelected); // rif biglietti non ancora venduti
      let data = await getDoc(addReDay);

      let totaleGiornaliero = data._document.data.value.mapValue.fields.totaleGiornaliero
        .integerValue
        ? data._document.data.value.mapValue.fields.totaleGiornaliero.integerValue
        : data._document.data.value.mapValue.fields.totaleGiornaliero.doubleValue; // ftech del totsle giornaliero
      let valueFalse =
        data._document.data.value.mapValue.fields.arrVenditeDay.arrayValue.values[0]
          .stringValue; // serve per controllare se l'array delle vendite è vuoto

      let arrVenditeDay = []; // inizializzo la variabile nel caso in cui non ci fosse stata ancora una vendita

      // mappa l'array che contine il carrello da incassare formattandolo con la stringa che dovrà essere inserita nel db
      arrVenditeCarrello = arrVenditeCarrello.map((item) => {
        return `${item.key}/${item.nome}/${item.vUnita}`;
      });

      let totaleCarrello = getState().homeReducers.tot; // totale cattello prelevato dal reducer

      // se non ci sono state vendite allora assegno direttamente il carrello corrente al mio arrVenditeDay
      if (valueFalse === "false") {
        arrVenditeDay = arrVenditeCarrello;
      } else {
        // se c'è ne sono state allora prendo l'array che gia esisteva
        let arrVenditePre =
          data._document.data.value.mapValue.fields.arrVenditeDay.arrayValue.values;

        // lo mappo in un formato classico
        arrVenditePre = arrVenditePre.map((item) => item.stringValue);

        //bug per il bilgietto scaricato stornato e poi riscaricato
        arrVenditePre = arrVenditePre.map((item) => {
          const itemSplit = item.split("/");
          return {
            nome: itemSplit[1],
            key: itemSplit[0],
            vUnita: itemSplit[2],
          };
        });
        arrVenditeCarrello = arrVenditeCarrello.map((item) => {
          const itemSplit = item.split("/");
          return {
            nome: itemSplit[1],
            key: itemSplit[0],
            vUnita: itemSplit[2],
          };
        });

        arrVenditeDay = [...arrVenditePre, ...arrVenditeCarrello];

        // rimuovo i duplicati perche se esistono duplicati sarebbero degli storni
        let arrStorno = arrVenditeDay;
        arrStorno = arrStorno.filter((item) => item.nome.includes("storno") === true);
        arrStorno.forEach((item) => {
          arrVenditeDay = arrVenditeDay.filter((x) => x.key !== item.key);
        });
        //----------

        // lo appendo a quello esistente

        // li rimappo nel formato del db nello
        arrVenditeDay = arrVenditeDay.map((item) => {
          return `${item.key}/${item.nome}/${item.vUnita}`;
        });

        if (arrVenditeDay.length === 0) {
          arrVenditeDay.push("false");
        }
      }

      if (arrDaEliminare !== []) {
        // elimina mazzette gia vendute
        for (let i = 0; i < arrDaEliminare.length; i++) {
          let split = arrDaEliminare[i].split("+");
          let addRef = doc(db, `${refMag[0]}.magazzino`, split[2]); // riferimento del db nello specifici dellla mazzetta
          let addRefV = doc(addRef, "mazzetta", "venduto"); // rif biglietti non ancora venduti
          let addRefMV = doc(addRefV, "serie", split[1] + split[0]); // rif specifica colleiozione dove si trova il campo gratta non venduti
          let addRefnV = doc(addRef, "mazzetta", "nonVenduto"); // rif biglietti non ancora venduti
          let addRefM = doc(addRefnV, "serie", split[1] + split[0]); // rif specifica colleiozione dove si trova il campo gratta non venduti
          deleteDoc(addRefM);
          deleteDoc(addRefMV);
        }

        arrDaEliminare = [];
        //----------------------------------------------------------------
      }

      totaleGiornaliero = Number(totaleGiornaliero) + Number(totaleCarrello);
      await updateDoc(addReDay, { arrVenditeDay });
      await updateDoc(addReDay, { totaleGiornaliero });

      await dispatch(incassaSuccess(totaleGiornaliero, arrDaEliminare));
      document.getElementById("btn-incassa").disabled = false;

      //   dispatch(incassaSuccess("qualcosa"));
    } catch (error) {
      console.log(error);
      dispatch(incassaFail(error));
    }
  };
};

export const incassaStart = () => {
  return {
    type: INCASSA_START,
  };
};

export const incassaSuccess = (totaleGiornaliero, arrDaEliminare) => {
  return {
    type: INCASSA_SUCCESS,
    totaleGiornaliero: totaleGiornaliero,
    arrDaEliminare: arrDaEliminare,
    //action
  };
};

export const incassaFail = (error) => {
  return {
    type: INCASSA_FAIL,
    error: error,
  };
};

export const stornaBiglietto = (codiceGratta) => {
  return async (dispatch, getState) => {
    dispatch(stornaBigliettoStart());
    try {
      const codiceM = String(codiceGratta).slice(0, 4);
      const serieM = String(codiceGratta).slice(4, 11);
      const progM = String(codiceGratta).slice(11);
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      // verifco che il codice del gratta e vicni sia un codice esistente
      await dispatch(modelExsist(codiceM));
      const exsist = await getState().caricaGrattaReducers.exsist;
      if (!exsist) {
        dispatch(stornaBigliettoFail(true, "Il codice del Gratta&Vinci non è valido"));
      } else {
        // controlliamo se il gratta e vinci è presente nel carrello delle vendite
        const uiVendita = await getState().homeReducers.uiVendita; //rivelo l'attuale array della vendita
        const nome = await getState().caricaGrattaReducers.nome;

        // controllo se lo storno che volgio effettuare è presente nel  carrello( uiVenidta)
        const uiControllo = uiVendita.map((item) => item.key);
        if (uiControllo.includes(codiceGratta)) {
          dispatch(
            stornaBigliettoFail(
              true,
              "Il Gratta&Vinci è presente nel carrello se vuoi stornarlo eliminalo direttamente da li"
            )
          );
          return;
        } else {
          // contriliamo adesso se la mazzetta gia è presente nel magazzino
          const addRef = doc(db, `${refMag[0]}.magazzino`, nome);
          const addRefV = doc(addRef, "mazzetta", "venduto");
          const addRefnV = doc(addRef, "mazzetta", "nonVenduto");
          const addRefM = doc(addRefV, "serie", codiceM + serieM);
          const addRefMnV = doc(addRefnV, "serie", codiceM + serieM);

          let data = await getDoc(addRefMnV);
          const mExist = data.exists();
          if (mExist) {
            data = await getDoc(addRefMnV);
            let arrMazzetta =
              data._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
                (item) => item.stringValue
              );
            data = await getDoc(addRefM);
            let arrMazzettaV =
              data._document.data.value.mapValue.fields.arrMazzettaV.arrayValue.values.map(
                (item) => item.stringValue
              );

            if (arrMazzetta.includes(codiceGratta)) {
              dispatch(
                stornaBigliettoFail(
                  true,
                  "il biglietto non è ancora stato venduto quindi non è possibile stornarlo"
                )
              );
            } else {
              // logica per stornarlo se la mazzetta ancora esiste
              // 1- eliminare la mazzetta dal Venduto ed inserirla nel Non Venduto
              arrMazzettaV = arrMazzettaV.filter((item) => item !== codiceGratta);
              arrMazzetta.push(codiceGratta);
              arrMazzetta = arrMazzetta.filter((item) => item !== "venduta");

              if (arrMazzettaV.length === 0) {
                arrMazzettaV.push("false");
              }

              setDoc(addRefM, { arrMazzettaV });
              setDoc(addRefMnV, { arrMazzetta });
              const vUnita = await getState().caricaGrattaReducers.vUnita;
              const nome = await getState().caricaGrattaReducers.nome;
              dispatch(decrementaTotCarrello(vUnita));
              // aggiono la uiVendita

              // cerco la giacenza di quel biglietto del
              let dataG = await getDocs(collection(addRefnV, `serie`));

              let arrGiac = dataG.docs.map((x) =>
                x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
                  (item) => item.stringValue
                )
              );
              let newArray = [];
              arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
              newArray = newArray.filter((item) => item !== "venduta");
              let nBiglietti = newArray.length;
              uiVendita.forEach((element) => {
                if (element.nome === nome || element.nome.slice(7) === nome) {
                  element.nBiglietti = nBiglietti;
                }
              });

              uiVendita.unshift({
                nome: "storno " + nome,
                serieM: serieM,
                progM: progM,
                vUnita: -vUnita,
                key: codiceGratta,
                nBiglietti: nBiglietti,
              });

              // 2 aggiornare L'arrray della vendita di quel giorno
              await dispatch(stornaBigliettoSuccess(uiVendita));
            }
          } else {
            // logica la mazzetta non è in magazzino
            let arrMazzetta = [codiceGratta];
            let arrMazzettaV = ["false"];
            //1- creare la mazzetta in magazzino
            //2- caricare il biglietto
            setDoc(addRefM, { arrMazzettaV });
            setDoc(addRefMnV, { arrMazzetta });
            // 3- aggiornare la vednita di quel giorno
            const vUnita = await getState().caricaGrattaReducers.vUnita;
            const nome = await getState().caricaGrattaReducers.nome;
            dispatch(decrementaTotCarrello(vUnita));

            // cerco la giacenza di quel biglietto del
            let dataG = await getDocs(collection(addRefnV, `serie`));

            let arrGiac = dataG.docs.map((x) =>
              x._document.data.value.mapValue.fields.arrMazzetta.arrayValue.values.map(
                (item) => item.stringValue
              )
            );
            let newArray = [];
            arrGiac.map((arr) => (newArray = [...newArray, ...arr]));
            newArray = newArray.filter((item) => item !== "venduta");
            let nBiglietti = newArray.length;
            uiVendita.forEach((element) => {
              if (element.nome === nome || element.nome.slice(7) === nome) {
                element.nBiglietti = nBiglietti;
              }
            });

            uiVendita.unshift({
              nome: "storno " + nome,
              serieM: serieM,
              progM: progM,
              vUnita: -vUnita,
              key: codiceGratta,
              nBiglietti: nBiglietti,
            });

            // 2 aggiornare L'arrray della vendita di quel giorno
            await dispatch(stornaBigliettoSuccess(uiVendita));
          }
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(stornaBigliettoFail(error, "generico"));
    }
  };
};

export const stornaBigliettoStart = () => {
  return {
    type: STORNA_BIGLIETTO_START,
  };
};

export const stornaBigliettoSuccess = (uiVendita) => {
  return {
    type: STORNA_BIGLIETTO_SUCCESS,
    //action
    uiVendita: uiVendita,
  };
};

export const stornaBigliettoFail = (error, message) => {
  return {
    type: STORNA_BIGLIETTO_FAIL,
    error: error,
    message: message,
  };
};

export const errorReset = () => {
  return {
    type: ERROR_RESET,
  };
};

export {
  SCARICA_START,
  SCARICA_SUCCESS,
  SCARICA_FAIL,
  ELIMINA_VENDITA_START,
  ELIMINA_VENDITA_SUCCESS,
  ELIMINA_VENDITA_FAIL,
  INCREMENTA_TOT_CARRELLO,
  DECREMENTA_TOT_CARRELLO,
  FETCH_DAY_TOTAL_START,
  FETCH_DAY_TOTAL_FAIL,
  FETCH_DAY_TOTAL_SUCCESS,
  INCASSA_START,
  INCASSA_SUCCESS,
  INCASSA_FAIL,
  ERROR_RESET,
  STORNA_BIGLIETTO_FAIL,
  STORNA_BIGLIETTO_SUCCESS,
  STORNA_BIGLIETTO_START,
  SCARICA_MAZZETTA_START,
  SCARICA_MAZZETTA_FAIL,
  SCARICA_MAZZETTA_SUCCESS,
  MOSTRA_VENDITE_START,
  MOSTRA_VENDITE_SUCCESS,
  MOSTRA_VENDITE_FAIL,
};
