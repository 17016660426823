import { collection, getDocs, query, where } from "@firebase/firestore";
import { RemoveDuplicates } from "./RemoveDuplicate";

export const arrGrattaMag = async (db, price) => {
  let arrGratta = await getDocs(
    query(collection(db, `modelli`), where("vUnita", "==", price))
  );
  arrGratta = arrGratta.docs.map((doc) => {
    return {
      codiceGratta: doc._document.data.value.mapValue.fields.codiceGratta.integerValue,
      nome: doc._document.data.value.mapValue.fields.nome.stringValue,
      imgM: doc._document.data.value.mapValue.fields.imgM.stringValue,
    };
  });

  arrGratta = RemoveDuplicates(arrGratta, "nome");

  return arrGratta;
};
