export const afterStornoTour = [
  {
    target: `.navbar-toggle`,
    content: `Bravissimo, hai completato la prima parte del addestramento`,
    disableBeacon: true,
  },
  {
    target: `.stats-mini`,
    content: `Passiamo alle statistiche, premi questo tasto nel menu laterale ed andiamo a vederle più nel dettaglio`,
  },
];
