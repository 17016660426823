import * as actionTypes from "../actions/handleMagazzino";

const initialState = {
  error: false,
  loading: false,

  listGrattaArr: {},
  listgratta: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_START:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        //action

        listGrattaArr: action.listGrattaArr,
        listgratta: action.listGratta,

        //
        loading: false,
        error: false,
      };
    case actionTypes.FETCH_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
