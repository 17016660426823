import db from "../../Firebase-config";
import { getDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import { arrGrattaSM } from "../../functions/ArrGrattaSM";
import { arrGrattaG } from "../../functions/ArrGrattaG";
import { RemoveDuplicates } from "../../functions/RemoveDuplicate";
import "fix-date";
import { statsTourStart } from "./handleTour";
import { getType } from "../../functions/getArrayModel";
import { DataTranform } from "../../functions/DateTranform";
registerLocale("it", it);
const FETCH_STATISTICHE_G_START = "FETCH_STATISTICHE_G_START";
const FETCH_STATISTICHE_G_SUCCESS = "FETCH_STATISTICHE_G_SUCCESS";
const FETCH_STATISTICHE_G_FAIL = "FETCH_STATISTICHE_G_FAIL";

const FETCH_STATISTICHE_S_START = "FETCH_STATISTICHE_S_START";
const FETCH_STATISTICHE_S_SUCCESS = "FETCH_STATISTICHE_S_SUCCESS";
const FETCH_STATISTICHE_S_FAIL = "FETCH_STATISTICHE_S_FAIL";

// const FETCH_TOT_SINGLE_DAY_START = "FETCH_TOT_SINGLE_DAY_START";
// const FETCH_TOT_SINGLE_DAY_SUCCESS = "FETCH_TOT_SINGLE_DAY_SUCCESS";
// const FETCH_TOT_SINGLE_DAY_FAIL = "FETCH_TOT_SINGLE_DAY_FAIL";

export const fetchStatisticheG = (date) => {
  console.log(date);
  return async (dispatch) => {
    dispatch(fetchStatisticheGStart());
    try {
      let arrVenditeDay = [];
      let totaleGiornaliero = 0;
      // riferimento univo del magazzino Utente
      const email = localStorage.getItem("email");
      const refMag = email.split("@");
      const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici dellla mazzetta
      const addReDay = doc(addRefCalendario, "giorni", date); // rif biglietti non ancora venduti
      // verifico che esista gia un totale per quel giorno e se non esiste ne creo uno
      let data = await getDoc(addReDay);
      const dExist = data.exists();
      let vVuota = 0;
      if (dExist === false) {
        arrVenditeDay.push("false");
        vVuota = true;
        await setDoc(addReDay, { arrVenditeDay });
        await updateDoc(addReDay, { totaleGiornaliero });
      } else {
        vVuota = false;
        totaleGiornaliero = data._document.data.value.mapValue.fields.totaleGiornaliero
          .integerValue
          ? data._document.data.value.mapValue.fields.totaleGiornaliero.integerValue
          : data._document.data.value.mapValue.fields.totaleGiornaliero.doubleValue;
        arrVenditeDay =
          data._document.data.value.mapValue.fields.arrVenditeDay.arrayValue.values;
      }

      arrVenditeDay = arrVenditeDay.map((index) => {
        let arrStr = vVuota
          ? 0
          : index.stringValue.includes("false")
          ? 0
          : index.stringValue.split("/");

        return {
          nome: arrStr === 0 ? "0" : arrStr[1],
          key: arrStr === 0 ? "0" : arrStr[0],
          codiceM: arrStr === 0 ? "0" : arrStr[0].slice(0, 4),
          vUnita: arrStr === 0 ? 0 : Number(arrStr[2]),
        };
      });
      const arrStampaVenditeDay = arrVenditeDay;
      // rimuovo i duplicati perche se esistono duplicati sarebbero degli storni
      let arrStorno = arrVenditeDay;
      arrStorno = arrStorno.filter((item) => item.nome.includes("storno") === true);
      arrStorno.forEach((item) => {
        arrVenditeDay = arrVenditeDay.filter((x) => x.key !== item.key);
      });
      //----------

      const listGratta = (await getType())["type"];

      let listGrattaArr = {};

      for await (const item of listGratta) {
        listGrattaArr[item] = await arrGrattaG(db, item, arrVenditeDay);
        listGrattaArr[item] = await Promise.all(listGrattaArr[item]);
        listGrattaArr[item] = RemoveDuplicates(listGrattaArr[item], "nome");
      }

      dispatch(fetchStatisticheGSuccess(listGrattaArr, arrStampaVenditeDay, listGratta));
    } catch (error) {
      dispatch(fetchStatisticheGFail(error));
      console.log(error);
    }
  };
};

export const fetchStatisticheGStart = () => {
  return {
    type: FETCH_STATISTICHE_G_START,
  };
};

export const fetchStatisticheGSuccess = (
  listGrattaArr,
  arrStampaVenditeDay,
  listGratta
) => {
  return {
    type: FETCH_STATISTICHE_G_SUCCESS,
    listGrattaArr,
    arrStampaVenditeDay,
    listGratta,
  };
};

export const fetchStatisticheGFail = (error) => {
  return {
    type: FETCH_STATISTICHE_G_FAIL,
    error: error,
  };
};

export const fetchStatisticheS = (date, day) => {
  return async (dispatch) => {
    dispatch(fetchStatisticheSStart());
    try {
      let dateArr = date.split("-");
      //trasormo la data
      let dataTemp = new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
      let days = 86400000; //number of milliseconds in a day
      let arrVenditeDay = [];
      let totaleGiornaliero = 0;
      let arrSettimanaVenidte = [];
      let arrChart = [];
      let arrChartSingleDay = [];
      let arrChartVenditeDay = [];
      let dataInizioStatistiche = null;
      let dataFineStatistiche = null;

      for (let i = 0; i < day; i++) {
        // sotttraggo la data
        let dataT = new Date(dataTemp - i * days);

        if (i === 1) {
          dataInizioStatistiche = dataT.toLocaleDateString();
        }
        if (i === day) {
          dataFineStatistiche = dataT.toLocaleDateString();
        }
        //azzero le varibaili del singolo fetch
        arrVenditeDay = [];
        totaleGiornaliero = 0;
        // riferimento univo del magazzino Utente
        const email = localStorage.getItem("email");
        const refMag = email.split("@");
        const addRefCalendario = doc(db, `${refMag[0]}.magazzino`, "calendario"); // riferimento del db nello specifici dellla mazzetta
        const addReDay = doc(
          addRefCalendario,
          "giorni",
         DataTranform(dataT)
        ); // rif della collection delle vendite di quel giorno che contiene un array delle vendite e un totale giornaliero
        // controllo se nella data ci sono gia  state delle vendite
        let data = await getDoc(addReDay);
        const dExist = data.exists();
        let vVuota = 0; // inizializzo la variable che mi serve come switch
        if (dExist === false) {
          // se non esiste inserisco inzializzo il giono con un array = 'false' ed tot = 0
          arrVenditeDay.push("false");
          vVuota = true;
          setDoc(addReDay, { arrVenditeDay });
          updateDoc(addReDay, { totaleGiornaliero });
        } else {
          // se gia esiste acquisisco i dati
          vVuota = false;
          totaleGiornaliero = data._document.data.value.mapValue.fields.totaleGiornaliero
            .integerValue
            ? data._document.data.value.mapValue.fields.totaleGiornaliero.integerValue
            : data._document.data.value.mapValue.fields.totaleGiornaliero.doubleValue;
          arrVenditeDay =
            data._document.data.value.mapValue.fields.arrVenditeDay.arrayValue.values;

          //----------
        }

        // mappo l'array delle vendite crendo un array di oggetti che corrispondo alle singole vendite del giorno
        arrVenditeDay = arrVenditeDay.map((item) => {
          let arrStr = vVuota
            ? 0
            : item.stringValue.includes("false")
            ? 0
            : item.stringValue.split("/");
          return {
            nome: arrStr === 0 ? "0" : arrStr[1],
            key: arrStr === 0 ? "0" : arrStr[0],
            codiceM: arrStr === 0 ? "0" : arrStr[0].slice(0, 4),
            vUnita: arrStr === 0 ? 0 : Number(arrStr[2]),
          };
        });

        arrSettimanaVenidte.push(...arrVenditeDay); // aggiono l'array della settimana/mese
        // rimuovo i duplicati perche se esistono duplicati sarebbero degli storni
        let arrStorno = arrVenditeDay;
        let newArrVedita = arrVenditeDay;

        arrStorno = arrStorno.filter((item) => item.nome.includes("storno") === true);

        await arrStorno.forEach((item) => {
          newArrVedita = newArrVedita.filter((x) => x.key !== item.key);
        });
        newArrVedita = newArrVedita.filter((x) => x.nome !== "0");

        //----------
        let dataChar = dataT.toLocaleDateString().split("/");
        arrChart.unshift([dataChar[0] + "/" + dataChar[1], Number(totaleGiornaliero)]);

        arrChartSingleDay.push(dataT.toLocaleDateString().replaceAll("/", "-"));
        arrChartVenditeDay.push(newArrVedita);
      }
      arrChart.unshift(["Giorno", "Tot €"]);

      arrVenditeDay = arrSettimanaVenidte; // assegano a venditeDay l'array della Settimana/mese solo per comodità
      // perchè aveo gia scritto il codice con arrVenditaDay

      // rimuovo i duplicati perche se esistono duplicati sarebbero degli storni
      let arrStorno = arrVenditeDay;
      arrStorno = arrStorno.filter((item) => item.nome.includes("storno") === true);
      arrStorno.forEach((item) => {
        arrVenditeDay = arrVenditeDay.filter((x) => x.key !== item.key);
      });
      //----------

      // creo un array per i diversi prezzi dei gratta e vinci
      const listGratta = (await getType())["type"];
      let listGrattaArr = {};
      for await (const item of listGratta) {
        listGrattaArr[item] = await arrGrattaSM(
          db,
          item,
          arrVenditeDay,
          arrChartSingleDay,
          arrChartVenditeDay
        );
        listGrattaArr[item] = await Promise.all(listGrattaArr[item]);
        listGrattaArr[item] = RemoveDuplicates(listGrattaArr[item], "nome");
      }

      // passo tutto nel reducer cosi i miei  array sono nello store
      dispatch(
        fetchStatisticheSSuccess(
          listGrattaArr,
          arrChart,
          dataInizioStatistiche,
          dataFineStatistiche,
          listGratta
        )
      );
      dispatch(statsTourStart());
    } catch (error) {
      dispatch(fetchStatisticheSFail(error));
      console.log(error);
    }
  };
};

export const fetchStatisticheSStart = () => {
  return {
    type: FETCH_STATISTICHE_S_START,
  };
};

export const fetchStatisticheSSuccess = (
  listGrattaArr,
  arrChart,
  dataInizioStatistiche,
  dataFineStatistiche,
  listGratta
) => {
  return {
    type: FETCH_STATISTICHE_S_SUCCESS,

    listGrattaArr,
    arrChart,
    dataInizioStatistiche,
    dataFineStatistiche,
    listGratta,
  };
};

export const fetchStatisticheSFail = (error) => {
  return {
    type: FETCH_STATISTICHE_S_FAIL,
    error: error,
  };
};

export {
  FETCH_STATISTICHE_G_START,
  FETCH_STATISTICHE_G_SUCCESS,
  FETCH_STATISTICHE_G_FAIL,
  FETCH_STATISTICHE_S_START,
  FETCH_STATISTICHE_S_SUCCESS,
  FETCH_STATISTICHE_S_FAIL,
};
