export const homeTour = [
  {
    target: `.navbar-toggle`,
    content: `Perfetto sei nella Home `,
    disableBeacon: true,
  },

  {
    target: `.search`,
    content: `Clicca con il Mouse al centro di questa barra e dovrebbe diventare di colore blu 
    dopo di che scelgi un Gratta&Vinci che vorresti vendere e spara il codice con il lettore BarCode che ti abbiamo 
    dato in dotazione `,
  },
];
