//#region TUTOR TEST
//Tour.js
import React from "react";
import JoyRide, { EVENTS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { tourSelector } from "../store/actions/handleTour";

// Tour component
const Tour = ({ run, tour }) => {
  const dispatch = useDispatch();
  const { startStatsTour } = useSelector((state) => state.tourReducer);

  const handleJoyrideCallback = (data) => {
    const { type } = data;
    if (type === EVENTS.TOUR_END) {
      if (startStatsTour) {
        dispatch(tourSelector(false));
      }
    }
  };

  return (
    <>
      <JoyRide
        steps={tour}
        continuous={true}
        showSkipButton={true}
        run={run}
        callback={handleJoyrideCallback}
      />
    </>
  );
};
export default Tour;

//#endregion
