export const searchBarTour = [
  {
    target: ".search",
    content:
      "Bravissimo hai scaricato il tuo primo Gratta&Vinci adesso vediamo un pò nel dettaglio il carrello",
    disableBeacon: true,
  },
  {
    target: ".hd-nome",
    content: "Nome del Gratta&Vinci",
  },
  {
    target: ".hd-serieM",
    content: "Numero di serie della mazzetta del Gratta&Vinci che hai scaricato",
  },
  {
    target: ".hd-progM",
    content: "Numero Progressivo del Gratta&Vinci",
  },
  {
    target: ".hd-vUnita",
    content: "Valore del Gratta&Vinci in €",
  },
  {
    target: ".hd-giacenza",
    content: "Giacienza della tipologia di Gratta&Vinci",
  },
  {
    target: ".icon-delete",
    content: "Tasto per eliminare la vendita",
  },
  {
    target: ".btn-carrello",
    content: "Tutto chiaro ? Adesso incassiamo la vendita",
  },
];
