import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { propostaOrdine } from "../functions/PropostaOrdine";
// Create styles

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  title: {
    margin: 4,
    fontSize: 20,
    textAlign: "justify",
    width: 400,
    marginLeft: 200,
  },
  textTitle: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 200,
    marginLeft: 40,
  },
  footer: {
    margin: 4,
    fontSize: 10,
    textAlign: "justify",
    width: 300,
    marginLeft: 40,
  },
  textGiacenze: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  textVmg: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 70,
  },
  textPo: {
    margin: 4,
    fontSize: 14,
    textAlign: "justify",
    width: 150,
  },
  divClass: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});

// Create Document Component
function CreatePdf({ arrPropostaPDF, arrGiacenze }) {
  const arrPropostaPDFFilter = [];
  const arrPO = [];
  let totMag = 0;
  arrPropostaPDF = arrPropostaPDF.forEach((item) => {
    let po = propostaOrdine(item.vmg, item.nBiglietti, item.nUnita);
    if (po !== 0) {
      arrPropostaPDFFilter.unshift(item);
      arrPO.unshift(po);
    }
  });

  return (
    <PDFViewer style={styles.viewer}>
      {/* Start of the document*/}
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>Report di tutti Biglietti</Text>
          </View>

          <View style={styles.section}>
            {arrGiacenze.map((item, index) => {
              return (
                <div style={styles.divClass}>
                  <Text style={styles.textTitle}>{item.nome} </Text>
                  <Text style={styles.textGiacenze}>VMG : {item.vmg} </Text>
                  <Text style={styles.textGiacenze}>Giacienza : {item.nBiglietti} </Text>
                </div>
              );
            })}
          </View>
          <View style={styles.section}>
            {arrGiacenze.forEach((item, index) => {
              // toale magazzino
              totMag = item.nBiglietti * item.vUnita + totMag;
            })}
            <div style={styles.section}>
              <Text style={styles.footer}>
                Totale Valore Magazzino : {totMag - (totMag * 8) / 100}
              </Text>
              <Text style={styles.footer}>Totale Ricavo : {totMag}</Text>
              <Text style={styles.footer}>Totale Margine : {(totMag * 8) / 100}</Text>
            </div>
          </View>
          <View style={styles.section}>
            <Text style={styles.footer}>VMG = Vendita media giornaliera</Text>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.title}>Proposta Ordine</Text>
          </View>

          <View style={styles.section}>
            {arrPropostaPDFFilter.map((item, index) => {
              return (
                <div style={styles.divClass}>
                  <Text style={styles.textTitle}>{item.nome} </Text>
                  <Text style={styles.textVmg}>VMG : {item.vmg} </Text>
                  <Text style={styles.textVmg}>G : {item.nBiglietti} </Text>
                  <Text style={styles.textPo}>
                    PO : {arrPO[index]}
                    {arrPO[index] === 1 ? " mazzetta" : " mazzette"}
                  </Text>
                </div>
              );
            })}
          </View>
          <View style={styles.section}>
            <Text style={styles.footer}>VMG = Vendita media giornaliera</Text>
            <Text style={styles.footer}>G = Giacienza</Text>
            <Text style={styles.footer}>PO = PropostaOrdine</Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}
export default CreatePdf;
