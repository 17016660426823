import React from "react";
import "../styles/StatsCard.css";
import ReactTooltip from "react-tooltip";
import { propostaOrdine } from "../functions/PropostaOrdine";

function StatsCard({
  nome,
  codiceM,
  nBigliettiVenduti,
  tBigliettiVenduti,
  vmg,
  tmg,
  periodo,
  arrChart,
  imgM,
  nBiglietti,
  nUnita,
}) {
  let po = propostaOrdine(vmg, nBiglietti, nUnita);

  return (
    <div className="stats-card">
      <div className="stats-head">
        <h2>{nome}</h2>
        <img src={imgM} loading="lazy" alt="" />
      </div>
      <div className="stats-body-mobile">
        <div className="stats-body btg">
          <div className="row_stats">
            <p className="bv">Biglietti Venduti :</p>
            <span> {nBigliettiVenduti}</span>
          </div>
          <div className="row_stats">
            <p className="tbv">Totale Biglietti Venduti :</p>
            <span>{tBigliettiVenduti}€</span>
          </div>
          <div className="row_stats">
            <p className="gb">Giacienza :</p>
            <span>{nBiglietti}</span>
          </div>
        </div>
        <div className="stats-body vtp">
          <div className="row_stats">
            <p
              data-tip={
                periodo === "ND"
                  ? `Vendita Media Giornaliera <br /> non disponibile perchè<br /> stai consultando le statistiche Giornaliere`
                  : `Vendita Media Giornaliera <br /> calcolata nel periodo selezionato`
              }
              data-delay-show="400"
              data-type="info"
              className="vmg"
            >
              Vendita media Giornaliera:
            </p>
            <span>{vmg}</span>
            <ReactTooltip multiline={true} />
          </div>
          <div className="row_stats">
            <p
              data-tip={
                periodo === "ND"
                  ? `Totale Medio Giornaliero <br /> non disponibile perchè<br /> stai consultando le statistiche Giornaliere`
                  : `Totale Medio Giornaliero <br /> calcolato nel periodo selezionato`
              }
              data-delay-show="400"
              data-type="info"
              className="tmg"
            >
              Totale medio giornaliero :
            </p>
            <span> {periodo === "ND" ? "ND" : tmg + "€"}</span>
            <ReactTooltip multiline={true} />
          </div>
          <div className="row_stats">
            <p
              data-tip={
                periodo === "ND"
                  ? `Proposta d'ordine non disponibile perchè <br /> <br /> stai consultando le statistiche Giornaliere`
                  : `Proposta d'ordine calcolata <br /> dalla vendita media giornaliera <br />  del periodo selezionato`
              }
              data-delay-show="400"
              data-type="info"
              className="po"
            >
              Proposta d'ordine:
            </p>
            <span>{periodo === "ND" ? "ND" : po}</span>
            <ReactTooltip multiline={true} />
          </div>
        </div>
      </div>
      {/* 
      <div className="chart-stat">
        {periodo === "ND" ? (
          <p>
            Grafico statistiche non disponibile <br /> perchè stai consultando <br />
            le statistiche giornaliere
          </p>
        ) : (
          // <Chart
          //   width={"auto"}
          //   height={"100px"}
          //   chartType="LineChart"
          //   loader={<div>Loading Chart</div>}
          //   data={arrChart}
          //   rootProps={{ "data-testid": "1" }}
          //   options={{
          //     title: `Grafico dei ${periodo} giorni precedenti alla data selezionata`,
          //     hAxis: { title: "Giorni" },
          //     vAxis: { title: "Tot" },
          //     legend: "none",
          //     animation: {
          //       startup: true,
          //       easing: "linear",
          //       duration: 1500,
          //     },
          //     enableInteractivity: true,
          //   }}
          // />
          <Chart
            options={chart.options}
            series={chart.series}
            type="line"
            width="400"
            height="120"
          />
        )}
      </div> */}
    </div>
  );
}

export default StatsCard;
