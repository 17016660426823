import { combineReducers } from "redux";
import caricaGrattaReducers from "./caricaGrattaReducer";
import homeReducers from "./homeReducer";
import authReducer from "./authReducer";
import magazzinoReducers from "./magazzinoReducer";
import singleMagReducers from "./singleMagReducer";
import statisticheReducers from "./statisticheReducer";
import tourReducer from "./tourReducer";

const rootReducer = combineReducers({
  caricaGrattaReducers,
  homeReducers,
  authReducer,
  magazzinoReducers,
  singleMagReducers,
  statisticheReducers,
  tourReducer,
});

export default rootReducer;
