import React from "react";
import { Link } from "react-router-dom";
import "../styles/GrattaCard.css";
import { passData } from "../store/actions/handleSingleMag";
import { useDispatch } from "react-redux";

function GrattaCard({ nome, codiceGratta, imgM }) {
  const dispatch = useDispatch();

  return (
    <div className="gratta-card">
      <h3>{nome}</h3>
      <Link
        to={{
          pathname: `/magazzino/${nome}`,
        }}
      >
        <img
          src={imgM}
          alt=""
          onClick={() => dispatch(passData(nome, codiceGratta, imgM))}
        />
        {/* <button>Vai</button> */}
      </Link>
    </div>
  );
}

export default GrattaCard;
