export const statsTour = [
  {
    target: `.navbar-toggle`,
    content: `Tutti i dati sono calcolati nei 7 giorni precedenti a quello 
    selezionato tramite l'apposito pulsante in alto a destra. Adesso attendi il caricamento delle 
    statische e poi comiciamo il tour `,
    disableBeacon: true,
  },
  {
    target: `.vmg`,
    content: `Vedita Media Giornaliera dello specifico prodotto`,
  },
  {
    target: `.tmg`,
    content: `Totale Medio Giornaliero dello specifico prodotto`,
  },
  {
    target: `.po`,
    content: `Questo è un dato importante perchè rappresenta la proposta 
    d'ordine automatica dello specifo prodotto che stai visualizzando,
     si hai capito bene, proposta d'ordine automatica
      calcolta in base al giorno corrente ed alla media giornaliera del prodotto,
       sappiamo tutto di te solo che ancora non lo sai.`,
  },
  {
    target: `.ico-pdf`,
    content: `Premendo questo tasto troverai un pdf stampabile del riepilogo del magazzino 
    con giacienza residua, vendita media giornaliera per ogni prodotto e nell'ultima pagina
     un proposta d'ordine compelta di tutti i prodotti. 
     E' comunque consigliabile utilizzare i dati della proposta d'ordine solo dopo aver utilizzato il programma 
     per almeno due settimane in modo tale di dare il tempo al software di immagazinare almeno un minimo di dati 
     per poter dare una proposta d'ordine accurata `,
  },
  {
    target: `.home-mini`,
    content: `Complimenti , sei stato bravissimo, hai completato il tour guidato, Torna alla Home 
    e comincia ad utilizzare EasyScratch in autonnomia`,
  },
];
