import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleMag,
  deleteMazzetta,
  changeSingleGrattaMag,
  aggiornaUiGratta,
  cleanArrUI,
} from "../store/actions/handleSingleMag";
import "../styles/SingleMag.css";
import * as BiIcons from "react-icons/bi";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as ImIcons from "react-icons/im";
import { errorReset } from "../store/actions/handleHome";
import Swal from "sweetalert2";
import "animate.css";
import { stateChange } from "../functions/stateChange";
import Navbar from "../components/Navbar";

function SingleMag(props) {
  const dispatch = useDispatch();
  const nome = useSelector((state) => state.singleMagReducers.nome);
  const codiceGratta = useSelector((state) => state.singleMagReducers.codiceGratta);
  const imgM = useSelector((state) => state.singleMagReducers.imgM);
  const nBiglietti = useSelector((state) => state.singleMagReducers.nBiglietti);
  const uiGratta = useSelector((state) => state.singleMagReducers.uiGratta);
  const uiGrattaV = useSelector((state) => state.singleMagReducers.uiGrattaV);

  //   try {
  //     nome = props.location.state.nome;
  //     codiceGratta = props.location.state.codiceGratta;
  //   } catch (error) {
  //     console.log(error);
  //   }

  const arrSerieGratta = useSelector((state) => state.singleMagReducers.arrSerieGratta);

  const [input, setInput] = useState("");

  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);

  useEffect(() => {
    if (token) {
      stateChange();
      dispatch(errorReset());
      dispatch(cleanArrUI());

      dispatch(fetchSingleMag(codiceGratta, nome));
    }
  }, [codiceGratta, dispatch, nome, token]);

  const handleInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  const deleteConferma = (serie) => {
    Swal.fire({
      title: `Sei sicuro di voler eliminare la mazzetta ??`,
      text: `La mazzetta ${serie} verra eliminata dal tuo magazzino`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "SI, ELIMINA !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMazzetta(serie, nome));
      }
    });
  };

  const cComeVenduto = async (x) => {
    Swal.fire({
      title: `Hai gia Venduto questo questo Gratta&Vinci ?`,
      text: `Se lo hai gia venduto premi ok ed il gratta ${x} verra assegnato come VENDUTO`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(changeSingleGrattaMag(x, "venduto", nome));
      }
    });
  };
  const cComeNonVenduto = async (x) => {
    Swal.fire({
      title: `Non hai ancora Venduto questo questo Gratta&Vinci ?`,
      text: `Se lo non lo hai ancora venduto premi ok ed il gratta ${x} verra assegnato come NON VENDUTO`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00D100",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK !!",
      cancelButtonText: "NO",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(changeSingleGrattaMag(x, "non venduto", nome));
      }
    });
  };

  const rederArrFilter = () => {
    return (
      arrSerieGratta
        // eslint-disable-next-line array-callback-return
        .filter((f) => {
          if (input === "") {
            return f;
          } else if (f.nome.toLowerCase().includes(input.toLowerCase())) {
            return f;
          }
        })
        .map((x) => {
          return (
            <div
              className="single-result"
              onClick={() => clickSerie(x.arrSerie, x.arrSerieV)}
            >
              <p>{x.nome}</p>
              <span>
                <IoIcons.IoMdSettings />
              </span>
              <span style={{ color: "red", marginLeft: "3%" }}>
                <RiIcons.RiDeleteBin2Fill onClick={() => deleteConferma(x.nome)} />
              </span>
            </div>
          );
        })
    );
  };

  const clickSerie = (arrS, arrSV) => {
    dispatch(aggiornaUiGratta(arrS, arrSV));
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="single-mag">
        <div className="head">
          <div className="searc-bar">
            <input
              type="text"
              placeholder="inserisci la serire della mazzetta"
              onChange={handleInput}
              value={input}
            />
            <span>
              <BiIcons.BiSearchAlt />
            </span>
          </div>
          <div className="head-img-title">
            <img src={imgM} alt="" />
            <div className="nome-gratta">
              <h1>{nome}</h1>
            </div>
            <span>{nBiglietti} biglietti </span>
          </div>
        </div>
        <div className="result">
          <div className="result-serie">
            <h2>{arrSerieGratta.length} Mazzette </h2>
            {rederArrFilter()}
          </div>
          {/* <h3>
          <BsIcons.BsArrowBarRight />
        </h3> */}
          <div className="result-mazzetta-container">
            <h2> In magazzino</h2>
            <div className="result-mazzetta">
              {uiGratta.map((x) => {
                return (
                  <div
                    className="single-result-mazzetta"
                    style={{
                      borderTopRightRadius: "40px",
                      borderBottomRightRadius: "40px",
                    }}
                  >
                    <span>{nome}</span>
                    <span>{x}</span>
                    <span style={{ marginLeft: "3vw" }}>
                      <ImIcons.ImArrowRight
                        onClick={() => cComeVenduto(x)}
                        style={{ color: "red", cursor: "pointer" }}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="result-mazzetta-container">
            <h2>Già Venduto</h2>
            <div className="result-mazzetta">
              {uiGrattaV.map((x) => {
                return x !== "false" ? (
                  <div
                    className="single-result-mazzetta"
                    style={{
                      borderTopLeftRadius: "40px",
                      borderBottomLeftRadius: "40px",
                    }}
                  >
                    <span style={{ marginRight: "3vw" }}>
                      <ImIcons.ImArrowLeft
                        onClick={() => cComeNonVenduto(x)}
                        style={{ color: "green", cursor: "pointer" }}
                      />
                    </span>
                    <span>{nome}</span>
                    <span>{x}</span>
                  </div>
                ) : (
                  <div className="single-result-mazzetta">
                    <span>ancora nessun biglietto venduto </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleMag;
